const downloadSVG = (name, elementID) => {
  const svg = document.getElementById(elementID).outerHTML;
  const blob = new Blob([svg.toString()], { type: 'image/svg+xml' });
  const element = document.createElement('a');
  element.download = `${name}.svg`;
  element.href = window.URL.createObjectURL(blob);
  element.click();
  element.remove();
};

export default downloadSVG;
