import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import arrowRightIcon from 'assets/icons/arrow-right.svg';
import arrowLeftIcon from 'assets/icons/arrow-left.svg';
import { Container, ArrowButton } from './styles';

function Paginate({
  totalPages,
  currentPage,
  onClick,
  onClickPrev,
  onClickNext,
}) {
  const pages = new Array(totalPages).fill(0);

  return (
    <Container>
      <Pagination>
        {pages.map((e, index) => (
          <Pagination.Item
            key={index + 1}
            onClick={() => onClick(index + 1)}
            active={index + 1 === currentPage}
          >
            {index + 1 < 10 ? `0${index + 1}` : index + 1}
          </Pagination.Item>
        ))}
        <ArrowButton icon={arrowLeftIcon} onClick={onClickPrev} />
        <ArrowButton icon={arrowRightIcon} onClick={onClickNext} />
      </Pagination>
    </Container>
  );
}

Paginate.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onClick: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};

Paginate.defaultProps = {
  totalPages: 0,
  currentPage: 1,
  onClick: () => {},
  onClickPrev: () => {},
  onClickNext: () => {},
};

export default Paginate;
