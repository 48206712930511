import styled from 'styled-components';
import theme from 'theme';

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  .forgot-password-row {
    > a {
      text-decoration: none;
      color: ${theme.colors.defaultColor};
      font-size: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
`;

export const ImgLogo = styled.img`
  object-fit: contain;
  max-width: 300px;
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    width: 150px;
    margin-bottom: 20px;
  }
`;

export const RowActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  margin-top: 40px;
`;
