import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-top: 40px;
`;

export const RowActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: column;
  align-items: center;

  button {
    text-transform: uppercase;
    margin-top: 20px;
  }
`;
