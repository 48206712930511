import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 425px;
  text-align: center;

  > img {
    width: 69px;
    height: 69px;
    margin-bottom: 32px;
  }

  > p {
    margin-bottom: 32px;
    font-family: 'Suvinil Sans';
    font-size: 24px;
    font-weight: bold;
  }
`;
