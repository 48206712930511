import { takeLatest, put } from 'redux-saga/effects';
import { GET, POST, PUT, DELETE } from 'config/constants/verbs';
import { USERS_BASE } from 'config/constants/endPoints';
import API from 'utils/API';
import { Types } from 'store/ducks/usersReducer';

function* requestListUsers({ page }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${USERS_BASE}/?page=${page || 1}`,
    });
    yield put({
      type: Types.USERS_LIST_SUCCESS,
      isLoading: 0,
      data: data.data,
      pagination: data.metadata,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_LIST_FAIL,
      isLoading: 0,
      error: error.response.data,
    });
  }
}

function* requestCreateUser({ payload }) {
  try {
    const { data } = yield API.requestServer({
      verb: POST,
      endpoint: USERS_BASE,
      data: {
        name: payload.name,
        email: payload.email,
        password: payload.password,
        roleId: payload.role,
      },
    });

    yield put({
      type: Types.USERS_CREATE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_CREATE_FAIL,
      error: error.response.data.message,
    });
  }
}

function* requestUpdateUser({ payload, id }) {
  try {
    const { data } = yield API.requestServer({
      verb: PUT,
      endpoint: `${USERS_BASE}/${id}`,
      data: {
        name: payload.name,
        email: payload.email,
        roleId: payload.role,
      },
    });

    yield put({
      type: Types.USERS_UPDATE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_UPDATE_FAIL,
      message: error.response.data.message,
    });
  }
}

function* requestDeleteUser({ id }) {
  try {
    const { data } = yield API.requestServer({
      verb: DELETE,
      endpoint: `${USERS_BASE}/${id}`,
    });

    yield put({
      type: Types.USERS_DELETE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_DELETE_FAIL,
      message: error.response.data.message,
    });
  }
}

function* requestFilterBySearchUser({ value }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${USERS_BASE}?search=${value}`,
    });

    yield put({
      type: Types.USERS_FILTER_BY_SEARCH_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_FILTER_BY_SEARCH_FAIL,
      message: error.response.data.message,
    });
  }
}

function* requestFilterByRoleUser({ role }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${USERS_BASE}?type=${role}`,
    });

    yield put({
      type: Types.USERS_FILTER_BY_ROLE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.USERS_FILTER_BY_ROLE_FAIL,
      message: error.response.data.message,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.USERS_LIST_REQUEST, requestListUsers);
  yield takeLatest(Types.USERS_CREATE_REQUEST, requestCreateUser);
  yield takeLatest(Types.USERS_UPDATE_REQUEST, requestUpdateUser);
  yield takeLatest(Types.USERS_DELETE_REQUEST, requestDeleteUser);
  yield takeLatest(
    Types.USERS_FILTER_BY_SEARCH_REQUEST,
    requestFilterBySearchUser,
  );
  yield takeLatest(Types.USERS_FILTER_BY_ROLE_REQUEST, requestFilterByRoleUser);
}
