import styled from 'styled-components';
import theme from 'theme/';

const { defaultInput, thirdGray, secondDarkGray, gray, black } = theme.colors;

export const ContainerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Table = styled.table`
  width: 100%;
  font-family: 'Trebuchet MS';
`;

export const TableBody = styled.tbody``;
export const TableHead = styled.thead``;

export const Td = styled.td`
  text-align: left;
  padding: 12px 0 10px 12px;
  border-bottom: 1px solid ${thirdGray};
  color: ${secondDarkGray};

  &:not(:last-child) {
    padding: 12px 10px;
  }
`;

export const Th = styled.th`
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  padding: 10px;
  border: none;
  background-color: ${gray};
  color: ${black};
  white-space: pre;
`;

export const Tr = styled.tr`
  font-size: ${theme.font.fontSizeDefault};
  width: 100%;

  &:hover {
    background: ${defaultInput};
  }
`;
