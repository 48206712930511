import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import urls from 'config/constants/urls';
import Button from 'components/core/Button';
import FormInput from 'components/core/FormInput';
import BackgroundHalfContainer from 'components/core/BackgroundHalfContainer';
import { Creators as AuthActions } from 'store/ducks/authReducer';
import { Body, RowActions, Container } from './styles';

const AuthForm = () => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.authReducer);
  const intl = useIntl();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required(<FormattedMessage id="unfilledEmail" />),
    password: yup.string().required(<FormattedMessage id="unfilledPassword" />),
  });

  useEffect(() => {
    if (error.type) {
      toast.error(intl.formatMessage({ id: 'invalidUserOrPassword' }));
    }
  }, [error]);

  return (
    <BackgroundHalfContainer loading={isLoading}>
      <Container>
        <Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(AuthActions.authUserRequest(values));
            }}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setSubmitting,
            }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={() => setSubmitting(false)}
                      isError={(errors.email && touched.email) || error.type}
                      error={errors.email || (error.type && '')}
                    >
                      <FormattedMessage id="emailRequired" />
                    </FormInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onFocus={() => setSubmitting(false)}
                      isError={
                        (errors.password && touched.password) || error.type
                      }
                      error={errors.password || (error.type && '')}
                    >
                      <FormattedMessage id="passwordRequired" />
                    </FormInput>
                  </Col>
                </Row>
                <Row className="px-4 justify-content-end forgot-password-row">
                  <Link to={urls.ROUTES.FORGOT_PASSWORD_EMAIL_ENTRY.path}>
                    <FormattedMessage id="forgotPassword" />
                  </Link>
                </Row>
                <RowActions>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    width="250px"
                    btnType="primary"
                    uppercase
                    fontWeight="600"
                  >
                    <FormattedMessage id="login" />
                  </Button>
                </RowActions>
              </Form>
            )}
          </Formik>
        </Body>
      </Container>
    </BackgroundHalfContainer>
  );
};

export default AuthForm;
