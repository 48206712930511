import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import Loader from 'components/core/Loader';
import backgroundImg from 'assets/img/mantra.webp';
import { Container, Content, ContainerImage } from './styles';

const BackgroundHalfContainer = ({
  children,
  title,
  text,
  customImg,
  loading,
}) => (
  <Container>
    <ContainerImage>
      <img src={backgroundImg} alt="" />
    </ContainerImage>
    <Content>
      <Loader loading={loading} />
      {customImg || <img src={logo} alt="" />}
      {title && <h4>{title}</h4>}
      {text && <p>{text}</p>}
      {children}
    </Content>
  </Container>
);

export default BackgroundHalfContainer;

BackgroundHalfContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  text: PropTypes.node,
  customImg: PropTypes.node,
  loading: PropTypes.number,
};

BackgroundHalfContainer.defaultProps = {
  title: null,
  text: null,
  customImg: null,
  loading: 0,
};
