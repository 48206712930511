import { takeLatest, put } from 'redux-saga/effects';
import { Types } from 'store/ducks/authReducer';
import { POST } from 'config/constants/verbs';
import API from 'utils/API';
import { AUTH_USER } from 'config/constants/endPoints';
import urls from 'config/constants/urls';
import { history } from 'utils/routes';

function* requestAuthUser({ payload }) {
  try {
    const { data } = yield API.requestServer({
      verb: POST,
      endpoint: AUTH_USER,
      data: payload,
    });
    Object.assign(API.defaults, {
      headers: { Authorization: `Bearer ${data.token}` },
    });
    localStorage.setItem('token', data.token);
    yield put({
      type: Types.AUTH_USER_SUCCESS,
      isLoading: false,
    });
    history.push(urls.LINKS.QR_CODES);
  } catch (error) {
    yield put({
      type: Types.AUTH_USER_FAIL,
      isLoading: false,
      message: error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.AUTH_USER_REQUEST, requestAuthUser);
}
