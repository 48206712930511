import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const ContainerImage = styled.div`
  position: relative;
  height: 100vh;
  background-size: cover;
  width: calc(100% - 725px);
  z-index: 4;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  &:before {
    animation: animationBefore 2s ease 1.5s 1 normal forwards running;
    transform-origin: 50% 50%;
    position: absolute;
    content: '';
    background: #435351;
    width: 60px;
    height: 95vh;
    left: unset;
    right: 0;
    top: unset;
    z-index: -1;
    bottom: 1px;
    visibility: hidden;
    @keyframes animationBefore {
      0% {
        opacity: 0.9;
        transform: translate(0px, 0px) rotate(0deg);
      }
      100% {
        visibility: visible;
        opacity: 1;
        right: -20px;
        transform: translate(0px, 0px) rotate(3deg);
      }
    }
  }

  &:after {
    animation: animationAfter 2s ease 1s 1 normal forwards running;
    transform-origin: 50% 50%;
    position: absolute;
    content: '';
    background: #4e6260;
    width: 60px;
    height: 90vh;
    left: unset;
    right: 0;
    top: unset;
    z-index: -2;
    bottom: 1px;
    visibility: hidden;
    @keyframes animationAfter {
      0% {
        opacity: 0.5;
        transform: translate(0px, 0px) rotate(0deg);
      }
      100% {
        visibility: visible;
        opacity: 1;
        right: -38px;
        transform: translate(0px, 0px) rotate(6deg);
      }
    }
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 158px;
  width: 725px;
  background: #fff;

  /* Logo */
  > img {
    width: 180px;
  }

  /* Title */
  h4 {
    margin-top: 40px;
    margin-bottom: 25px;
    font-weight: bold;
    font-family: 'Suvinil Sans', sans-serif;
    text-align: center;
  }

  > p {
    text-align: center;
    margin-bottom: 0;
    font-family: 'Roboto';
  }

  @media screen and (max-width: 767px) {
    padding: 40px 20px;
  }
`;
