const base = process.env.REACT_APP_BASE_URL;
const service = 'api';
const version = 'v1';
export const basePath = `${base}/${service}/${version}/`;

export const AUTH_USER = '/auth/signin';
export const QRCODES_BASE = '/qrcodes';
export const QRCODES_REDIRECT = '/qrcodes/redirect';
export const FORGOT_PASSWORD_USER_EMAIL = '/auth/forgot-password';
export const USERS_BASE = '/users';
export const CAMPAIGNS = '/campaigns';
export const MEDIUMS = '/mediums';
