import { all } from 'redux-saga/effects';
import * as AuthSaga from './authSaga';
import * as QrCodesSaga from './qrCodesSaga';
import * as ForgotPasswordSaga from './forgotPasswordSaga';
import * as UsersSaga from './usersSaga';
import * as CampaignSaga from './campaignSaga';
import * as MediumSaga from './mediumSaga';

function* Sagas() {
  yield all([
    AuthSaga.watcherSaga(),
    QrCodesSaga.watcherSaga(),
    ForgotPasswordSaga.watcherSaga(),
    UsersSaga.watcherSaga(),
    CampaignSaga.watcherSaga(),
    MediumSaga.watcherSaga(),
  ]);
}

export default Sagas;
