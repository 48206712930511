import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import qrCodesReducer from './qrCodesReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import usersReducer from './usersReducer';
import campaignReducer from './campaignReducer';
import mediumReducer from './mediumReducer';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    authReducer,
    qrCodesReducer,
    forgotPasswordReducer,
    usersReducer,
    campaignReducer,
    mediumReducer,
  });

const Reducers = (history) => appReducer(history);

export default Reducers;
