import { takeLatest, put } from 'redux-saga/effects';
import { GET, POST, PUT, DELETE } from 'config/constants/verbs';
import { MEDIUMS } from 'config/constants/endPoints';
import { Types } from '../ducks/mediumReducer';
import API from '../../utils/API';

function* requestMedium({ page }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${MEDIUMS}/?page=${page || 1}`,
    });

    yield put({
      type: Types.MEDIUM_SUCCESS,
      data: data.data,
      pagination: data.metadata,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_FAIL,
      message: error,
    });
  }
}

function* requestMediumForSelectList() {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${MEDIUMS}?perPage=10000`,
    });

    yield put({
      type: Types.MEDIUM_FOR_SELECT_LIST_SUCCESS,
      data: data.data,
      pagination: data.metadata,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_FOR_SELECT_LIST_FAIL,
      message: error,
    });
  }
}

function* requestCreateMedium({ payload }) {
  const { name, slug } = payload;
  try {
    const { data } = yield API.requestServer({
      verb: POST,
      endpoint: MEDIUMS,
      data: {
        name,
        slug,
      },
    });

    yield put({
      type: Types.MEDIUM_CREATE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_CREATE_FAIL,
      error: error.response,
    });
  }
}

function* requestUpdateMedium({ payload, id }) {
  const { name, slug } = payload;

  try {
    const { data } = yield API.requestServer({
      verb: PUT,
      endpoint: `${MEDIUMS}/${id}`,
      data: {
        name,
        slug,
      },
    });

    yield put({
      type: Types.MEDIUM_UPDATE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_UPDATE_FAIL,
      error: error.response,
    });
  }
}

function* requestDeleteMedium({ id }) {
  try {
    const { data } = yield API.requestServer({
      verb: DELETE,
      endpoint: `${MEDIUMS}/${id}`,
    });

    yield put({
      type: Types.MEDIUM_DELETE_SUCCESS,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_DELETE_FAIL,
      error: error.response,
    });
  }
}

function* requestMediumFilterByDate({ startDate, endDate }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${MEDIUMS}?startDate=${startDate}T00:00:00.000Z&endDate=${endDate}T23:59:59.000Z`,
    });

    yield put({
      type: Types.MEDIUM_FILTER_BY_DATE_SUCCESS,
      isLoading: 0,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_FILTER_BY_DATE_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

function* requestMediumFilterByName({ medium }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${MEDIUMS}/${medium.id}`,
    });

    yield put({
      type: Types.MEDIUM_FILTER_BY_NAME_SUCCESS,
      data: [data],
    });
  } catch (error) {
    yield put({
      type: Types.MEDIUM_FILTER_BY_NAME_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.MEDIUM_REQUEST, requestMedium);
  yield takeLatest(
    Types.MEDIUM_FOR_SELECT_LIST_REQUEST,
    requestMediumForSelectList,
  );
  yield takeLatest(Types.MEDIUM_CREATE_REQUEST, requestCreateMedium);
  yield takeLatest(Types.MEDIUM_UPDATE_REQUEST, requestUpdateMedium);
  yield takeLatest(Types.MEDIUM_DELETE_REQUEST, requestDeleteMedium);
  yield takeLatest(
    Types.MEDIUM_FILTER_BY_DATE_REQUEST,
    requestMediumFilterByDate,
  );
  yield takeLatest(
    Types.MEDIUM_FILTER_BY_NAME_REQUEST,
    requestMediumFilterByName,
  );
}
