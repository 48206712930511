import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/core/Button';
import Header from 'components/core/ContentHeader';
import Modal from 'components/core/Modal';
import Paginate from 'components/core/Paginate';
import Loader from 'components/core/Loader';
import { Creators as qrCodesActions } from 'store/ducks/qrCodesReducer';
import {
  Table,
  Th,
  TableBody,
  Tr,
  Td,
  TableHead,
} from 'components/core/Table/styles';
import moment from 'moment-timezone';
import {
  ContentWrapper,
  TableButtonsWrapper,
} from 'components/core/Wrappers/styles';
import editIcon from 'assets/icons/edit.svg';
import trashIcon from 'assets/icons/trash.svg';
import downloadDarkIcon from 'assets/icons/download-dark.svg';
import AddEditQrCodeForm from 'components/presentation/modal/AddEditQrCodeForm';
import QrCodeDownload from 'components/presentation/modal/QrCodeDownload';
import FilterBar from 'components/presentation/FilterBar';
import ModalStatus from 'components/presentation/modal/ModalStatus';
import ModalConfirmation from 'components/presentation/modal/ModalConfirmation';
import sortData from 'utils/sortFilter/sort';
import { SortButton } from 'components/core/Button/ButtonStyle';

moment.locale('pt-br');

const headers = [
  <FormattedMessage id="name" />,
  <FormattedMessage id="creationDate" />,
  <FormattedMessage id="lastEditDate" />,
  'Campaign',
  'Medium',
  <FormattedMessage id="view" />,
  '',
];

const QrCodesList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { data, isLoading, status, pagination } = useSelector(
    ({ qrCodesReducer }) => qrCodesReducer,
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFilter, setShowFilter] = useState(0);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [qrCodeData, setQrCodeData] = useState();
  const [ascDesc, setAscDesc] = useState(false);
  const [editData, setEditData] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [qrCodeID, setQrCodeID] = useState('');
  const [responseStatus, setResponseStatus] = useState('success');
  const [requestStatusType, setRequestStatusType] = useState();

  const handlePagination = (pageNumber) => {
    if (pageNumber === currentPage) {
      return;
    }
    dispatch(qrCodesActions.qrCodesListRequest(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handlePaginationPrev = () => {
    if (currentPage > 1) {
      dispatch(qrCodesActions.qrCodesListRequest(currentPage - 1));
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePaginationNext = () => {
    if (currentPage < pagination.totalPages) {
      dispatch(qrCodesActions.qrCodesListRequest(currentPage + 1));
      setCurrentPage(currentPage + 1);
    }
  };

  const handleEditQrCode = (params) => {
    requestStatusType && setRequestStatusType('');
    isError && setIsError(false);
    setShowAddModal(true);
    setEditData(params);
  };

  const handleModalDelete = (id) => {
    requestStatusType && setRequestStatusType('');
    isError && setIsError(false);
    setShowDeleteModal(true);
    setQrCodeID(id);
  };

  const openAddModal = () => {
    requestStatusType && setRequestStatusType('');
    isError && setIsError(false);
    setShowAddModal(true);
    setEditData(undefined);
  };

  const handleModalDownload = (qrCodeDownloadData) => {
    setShowDownloadModal(true);
    setQrCodeData(qrCodeDownloadData);
  };

  // Modal Contents

  const addQrCodeForm = (
    <AddEditQrCodeForm
      editData={editData}
      closeModal={() => setShowAddModal(false)}
      isError={isError}
    />
  );

  const downloadQrCode = <QrCodeDownload data={qrCodeData} />;

  const statusMethod = useCallback(() => {
    switch (requestStatusType) {
      case 'qrCode-already-registered':
        setIsError(true);
        toast.error(intl.formatMessage({ id: 'qrcodeRegistered' }));
        setRequestStatusType('');
        break;

      case 'is-not-allowed':
        setIsError(true);
        setShowAddModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(<FormattedMessage id="adminPermission" />);
        setShowStatusModal(true);
        break;

      case 'delete-success':
        setIsError(false);
        setShowDeleteModal(false);
        setShowStatusModal(true);
        break;

      case 'create-success':
        setIsError(false);
        toast.success(intl.formatMessage({ id: 'qrcodeAddSuccess' }));
        setRequestStatusType('');
        break;

      case 'update-success':
        setIsError(false);
        toast.success(intl.formatMessage({ id: 'qrcodeEditSuccess' }));
        setRequestStatusType('');
        break;

      default:
        break;
    }
  }, [requestStatusType]);

  useEffect(() => {
    dispatch(qrCodesActions.qrCodesListRequest(1));
    if (status) {
      setRequestStatusType(status);
    }
    statusMethod();
  }, [dispatch, status]);

  return (
    <>
      <Loader loading={isLoading} />
      <ModalStatus
        status={responseStatus}
        setIsOpen={setShowStatusModal}
        isOpen={showStatusModal}
        text={confirmationMessage}
      />
      <ModalConfirmation
        title={<FormattedMessage id="areYouSure" />}
        buttonName={<FormattedMessage id="delete" />}
        text={<FormattedMessage id="qrcodeDeleteConfirmMessage" />}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        loading={isLoading}
        confirmOnClick={() => {
          dispatch(qrCodesActions.qrCodesDeleteRequest(qrCodeID));
          setConfirmationMessage(<FormattedMessage id="qrcodeDeleteSuccess" />);
        }}
      />
      <Modal
        title={`Download ${qrCodeData && qrCodeData.name}`}
        setIsOpen={setShowDownloadModal}
        isOpen={showDownloadModal}
        content={downloadQrCode}
        loading={isLoading}
      />
      <Modal
        title={
          editData ? (
            <FormattedMessage id="editQrcode" />
          ) : (
            <FormattedMessage id="addQrcode" />
          )
        }
        setIsOpen={setShowAddModal}
        isOpen={showAddModal}
        content={addQrCodeForm}
        loading={isLoading}
      />
      <Header
        title="QR code"
        addButtonName={<FormattedMessage id="add" />}
        filterBtn
        onClickAdd={openAddModal}
        onClickFilter={() => setShowFilter(1)}
      />

      <ContentWrapper>
        <FilterBar
          display={showFilter}
          onClickClose={() => setShowFilter(0)}
          dateDispatch={qrCodesActions.qrCodesFilterByDateListRequest}
          campaignDispatch={qrCodesActions.qrCodesFilterByCampaignRequest}
          mediumDispatch={qrCodesActions.qrCodesFilterByMediumRequest}
          allListDispatch={qrCodesActions.qrCodesListRequest(1)}
        />
        <Table>
          <TableHead>
            <Tr>
              {headers.map((header, index) => (
                <Th key={index}>
                  {header}
                  {header && (
                    <SortButton
                      onClick={() =>
                        sortData(data, header, ascDesc, setAscDesc)
                      }
                    />
                  )}
                </Th>
              ))}
            </Tr>
          </TableHead>
          <TableBody>
            {data &&
              data.map((qrCode) => (
                <Tr key={qrCode.id}>
                  <Td>{qrCode.name}</Td>
                  <Td>{moment(qrCode.createdAt).utc().format('DD/MM/yyyy')}</Td>
                  <Td>{moment(qrCode.updatedAt).utc().format('DD/MM/yyyy')}</Td>
                  <Td>{qrCode.campaign.name}</Td>
                  <Td>{qrCode.medium.name}</Td>
                  <Td>{qrCode.view}</Td>
                  <Td>
                    <TableButtonsWrapper>
                      <Button
                        width="32px"
                        height="32px"
                        icon={downloadDarkIcon}
                        onClick={() => handleModalDownload(qrCode)}
                      />
                      <Button
                        width="32px"
                        height="32px"
                        icon={editIcon}
                        onClick={() => handleEditQrCode(qrCode)}
                      />
                      <Button
                        width="32px"
                        height="32px"
                        icon={trashIcon}
                        onClick={() => handleModalDelete(qrCode.id)}
                      />
                    </TableButtonsWrapper>
                  </Td>
                </Tr>
              ))}
          </TableBody>
        </Table>
        {pagination && (
          <Paginate
            totalPages={pagination.totalPages}
            currentPage={currentPage}
            onClick={(e) => handlePagination(e)}
            onClickPrev={handlePaginationPrev}
            onClickNext={handlePaginationNext}
          />
        )}
      </ContentWrapper>
    </>
  );
};

export default QrCodesList;
