import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import theme from 'theme/';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 15px;

  label {
    margin-bottom: 0;
  }
`;

export const InputStyle = styled(DatePicker)`
  position: relative;
  margin: 0;
  outline: 0;
  font-size: 12px;
  font-family: 'Montserrat';
  height: 38px;
  border: 1px solid #ecedf1;
  border-radius: 8px;
  width: 160px;
  padding: 8px 12px;
`;

export const Label = styled.label`
  color: ${theme.colors.darkGray};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
  font-family: 'Montserrat';
`;
