import styled from 'styled-components';
import Button from 'components/core/Button';
import theme from 'theme/';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  .pagination {
    .page-item > .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      font-family: 'Trebuchet MS';
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 2px;
      background: none;
      color: ${theme.colors.secondDarkGray};
      opacity: 30%;
      letter-spacing: 1px;

      :hover {
        opacity: 100%;
      }
    }

    .active {
      .page-link {
        opacity: 70%;
      }
    }
  }
`;

export const ArrowButton = styled(Button)`
  width: 40px;
  height: 40px;
  background: ${theme.colors.defaultInput};
  border-radius: 2px;
  margin-left: 8px;

  img {
    margin: 0;
  }

  :hover {
    background: ${theme.colors.thirdGray};
  }
`;
