import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-left: 295px;
`;

export const Content = styled.div`
  /* padding: 77px 20px 20px; */
  background: #fff;
  border-top-left-radius: 30px;
`;
