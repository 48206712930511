import styled, { css } from 'styled-components';
import sortIcon from 'assets/icons/sort.svg';
import theme from 'theme';

const {
  defaultColor,
  white,
  gray,
  danger,
  dangerHover,
  defaultHoverButton,
  secondary,
  black,
  defaultInput,
  secondGray,
  thirdGray,
} = theme.colors;

const { fontSizeDefault } = theme.font;

export default styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${white};
  padding: 8px;
  font-size: ${fontSizeDefault};
  outline: 0;
  border: none;
  white-space: nowrap;
  text-decoration: none;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => (height ? `${height}` : '48px')};
  font-family: ${({ fontFamily }) => (fontFamily ? `${fontFamily}` : 'Inter')};
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : 'normal')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}` : '4px'};
  background: ${secondGray};
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;

  &:hover {
    background: ${thirdGray};
  }

  ${({ btnType }) =>
    btnType === 'primary' &&
    css`
      background-color: ${defaultColor};

      &:hover {
        background-color: ${defaultHoverButton};
      }
    `};

  ${({ btnType }) =>
    btnType === 'danger' &&
    css`
      background-color: ${danger};

      &:hover {
        background-color: ${dangerHover};
      }
    `};

  ${({ btnType }) =>
    btnType === 'secondary' &&
    css`
      background-color: ${secondary};
      color: ${black};

      &:hover {
        background-color: ${gray};
      }
    `};

  ${({ btnType }) =>
    btnType === 'light' &&
    css`
      background-color: ${defaultInput};
      color: ${black};

      &:hover {
        background-color: ${gray};
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: #bbb;

      &:hover {
        background-color: #bbb;
      }
    `};

  > img {
    margin-right: 12px;
  }
`;

export const SortButton = styled.button`
  display: inherit;
  margin-left: 5px;
  background: transparent url(${sortIcon}) no-repeat center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  vertical-align: text-bottom;
`;
