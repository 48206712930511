import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Creators as campaignActions } from 'store/ducks/campaignReducer';
import { Creators as mediumActions } from 'store/ducks/mediumReducer';
import { Creators as qrCodesActions } from 'store/ducks/qrCodesReducer';
import Button from 'components/core/Button';
import FormInput from 'components/core/FormInput';
import Select from 'components/core/Select';
import downloadSVG from 'utils/downloadSVG';
import downloadIcon from 'assets/icons/download-primary.svg';
import { DownloadButton } from 'components/presentation/modal/QrCodeDownload/styles';
import { Container, EmptyQrcode, LinkLabel, LinksUrl } from './styles';

const AddEditQrCodeForm = ({
  isError,
  closeModal,
  errorMessage,
  onFocusName,
  editData,
}) => {
  const dispatch = useDispatch();
  const { campaignSelectData, campaignData } = useSelector(
    ({ campaignReducer }) => campaignReducer,
  );
  const { mediumSelectData, mediumData } = useSelector(
    ({ mediumReducer }) => mediumReducer,
  );

  const [finalRedirectLink, setFinalRedirectLink] = useState('');
  const [linkQrCode, setLinkQrCode] = useState('');

  const intl = useIntl();

  const initialValues = {
    name: editData.name ? editData.name : '',
    redirectURL: editData.urlRedirect ? editData.urlRedirect : '',
    slug: editData.slug ? editData.slug : '',
    campaign: editData.campaign.id ? editData.campaign.id : '',
    medium: editData.medium.id ? editData.medium.id : '',
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="unfilledName" />),
    redirectURL: yup
      .string()
      .required(<FormattedMessage id="unfilledRedirection" />),
    slug: yup
      .string()
      .required(<FormattedMessage id="unfilledSlug" />)
      .matches(
        /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
        <FormattedMessage id="slugExample" />,
      ),
    campaign: yup.string().required(<FormattedMessage id="unfilledCampaign" />),
    medium: yup.string().required(<FormattedMessage id="unfilledMedium" />),
  });

  const handleQrCodeCreateAndUpdate = (entryValues) => {
    const redirectURL = entryValues.redirectURL.replace(/\/$/g, '');
    const campaign = (campaignSelectData || campaignData).find(
      (element) => element.id === entryValues.campaign,
    );
    const medium = (mediumSelectData || mediumData).find(
      (element) => element.id === entryValues.medium,
    );

    setLinkQrCode(`https://qrcodes.suvinil.com.br/${entryValues.slug}`);

    setFinalRedirectLink(
      `${redirectURL}/p?utm_source=qr_code&utm_medium=${medium.slug}&utm_campaign=${campaign.slug}`,
    );

    if (editData.id) {
      dispatch(qrCodesActions.qrCodesUpdateRequest(entryValues, editData.id));
    } else {
      dispatch(qrCodesActions.qrCodesCreateRequest(entryValues));
    }
  };

  useEffect(() => {
    dispatch(campaignActions.campaignForSelectListRequest());
    dispatch(mediumActions.mediumForSelectListRequest());
  }, [dispatch]);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleQrCodeCreateAndUpdate(values);
        }}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <Row>
            <Col md="6">
              <Form>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="name"
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onFocus={onFocusName}
                      placeholder={intl.formatMessage({ id: 'enterFullname' })}
                      isError={(errors.name && touched.name) || errorMessage}
                      error={errors.name || errorMessage}
                    >
                      <FormattedMessage id="name" />
                    </FormInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="redirectURL"
                      id="redirectURL"
                      name="redirectURL"
                      value={values.redirectURL}
                      onChange={handleChange}
                      placeholder={intl.formatMessage({ id: 'enterRedirect' })}
                      isError={errors.redirectURL && touched.redirectURL}
                      error={errors.redirectURL}
                    >
                      <FormattedMessage id="urlRedirectLabel" />
                    </FormInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="slug"
                      id="slug"
                      name="slug"
                      value={values.slug}
                      onChange={handleChange}
                      placeholder="tintas-suvinil-exemplo"
                      isError={errors.slug && touched.slug}
                      error={errors.slug}
                      disabled={!!editData.slug}
                    >
                      <FormattedMessage id="slugRequired" />
                    </FormInput>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Select
                      type="campaign"
                      id="campaign"
                      name="campaign"
                      value={values.campaign}
                      onChange={handleChange}
                      options={campaignSelectData || campaignData}
                      isError={errors.campaign && touched.campaign}
                      error={errors.campaign}
                    >
                      <FormattedMessage id="campaignRequired" />
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Select
                      type="medium"
                      id="medium"
                      name="medium"
                      value={values.medium}
                      onChange={handleChange}
                      options={mediumSelectData || mediumData}
                      isError={errors.medium && touched.medium}
                      error={errors.medium}
                    >
                      <FormattedMessage id="mediumRequired" />
                    </Select>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-2">
                  {!isError && linkQrCode ? (
                    <Button
                      type="button"
                      btnType="secondary"
                      onClick={closeModal}
                      width="200px"
                      uppercase
                    >
                      <FormattedMessage id="close" />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      btnType="primary"
                      onClick={handleSubmit}
                      width="200px"
                      uppercase
                    >
                      {editData.id ? (
                        <FormattedMessage id="edit" />
                      ) : (
                        <FormattedMessage id="add" />
                      )}
                    </Button>
                  )}
                </Row>
              </Form>
            </Col>
            <Col md="6" className="mt-2">
              <Row className="justify-content-center">
                {!isError && linkQrCode ? (
                  <QRCode id="qrcode" value={linkQrCode} size={220} />
                ) : (
                  <EmptyQrcode />
                )}
              </Row>
              {!isError && linkQrCode && (
                <>
                  <Row className="mt-2 d-flex justify-content-center">
                    <DownloadButton
                      onClick={() => downloadSVG(values.name, 'qrcode')}
                      uppercase
                    >
                      <FormattedMessage id="downloadQrcode" />

                      <img src={downloadIcon} alt="" />
                    </DownloadButton>
                  </Row>
                  <Row>
                    <Col md="12" className="mt-4">
                      <LinkLabel>
                        <FormattedMessage id="qrcodeLinkLabel" />
                      </LinkLabel>
                      <LinksUrl>{linkQrCode}</LinksUrl>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <LinkLabel>
                        <FormattedMessage id="qrcodeFinalUrl" />
                      </LinkLabel>
                      <LinksUrl>{finalRedirectLink}</LinksUrl>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
};

AddEditQrCodeForm.propTypes = {
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  closeModal: PropTypes.func,
  onFocusName: PropTypes.func,
  editData: PropTypes.shape({
    urlRedirect: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    campaign: PropTypes.shape({
      id: PropTypes.string,
    }),
    medium: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

AddEditQrCodeForm.defaultProps = {
  isError: false,
  errorMessage: '',
  closeModal: () => {},
  onFocusName: () => {},
  editData: {
    urlRedirect: '',
    slug: '',
    id: '',
    name: '',
    campaign: {
      id: '',
    },
    medium: {
      id: '',
    },
  },
};

export default AddEditQrCodeForm;
