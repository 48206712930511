import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from 'components/core/Button';
import Header from 'components/core/ContentHeader';
import Modal from 'components/core/Modal';
import Paginate from 'components/core/Paginate';
import Loader from 'components/core/Loader';
import { Creators as campaignActions } from 'store/ducks/campaignReducer';
import {
  Table,
  Th,
  TableBody,
  Tr,
  Td,
  TableHead,
} from 'components/core/Table/styles';
import moment from 'moment-timezone';
import {
  ContentWrapper,
  TableButtonsWrapper,
} from 'components/core/Wrappers/styles';
import editIcon from 'assets/icons/edit.svg';
import trashIcon from 'assets/icons/trash.svg';
import AddEditSlugForm from 'components/presentation/modal/AddEditSlugForm';
import FilterBar from 'components/presentation/FilterBar';
import ModalStatus from 'components/presentation/modal/ModalStatus';
import ModalConfirmation from 'components/presentation/modal/ModalConfirmation';
import sortData from 'utils/sortFilter/sort';
import { SortButton } from 'components/core/Button/ButtonStyle';

moment.locale('pt-br');

const headers = [
  <FormattedMessage id="name" />,
  <FormattedMessage id="slug" />,
  <FormattedMessage id="creationDate" />,
  <FormattedMessage id="lastEditDate" />,
  '',
];

const CampaignList = () => {
  const dispatch = useDispatch();
  const { campaignData, isLoading, pagination, status } = useSelector(
    ({ campaignReducer }) => campaignReducer,
  );
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showFilter, setShowFilter] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ascDesc, setAscDesc] = useState(false);
  const [editData, setEditData] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [campaignID, setCampaignID] = useState('');
  const [responseStatus, setResponseStatus] = useState('success');
  const [requestStatusType, setRequestStatusType] = useState();

  const handlePagination = (pageNumber) => {
    if (pageNumber === currentPage) {
      return;
    }
    dispatch(campaignActions.campaignRequest(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handlePaginationPrev = () => {
    if (currentPage > 1) {
      dispatch(campaignActions.campaignRequest(currentPage - 1));
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePaginationNext = () => {
    if (currentPage < pagination.totalPages) {
      dispatch(campaignActions.campaignRequest(currentPage + 1));
      setCurrentPage(currentPage + 1);
    }
  };

  const handleEditCampaign = (params) => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setEditData(params);
    setErrorMessage('');
  };

  const handleModalDelete = (id) => {
    requestStatusType && setRequestStatusType('');
    setShowDeleteModal(true);
    setCampaignID(id);
  };

  const handleAddCampaign = () => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setErrorMessage('');
    setEditData(undefined);
  };

  const statusMethod = useCallback(() => {
    switch (requestStatusType) {
      case 'campaign-already-registered':
        setErrorMessage(<FormattedMessage id="nameOrSlugRegistered" />);
        setRequestStatusType('');
        break;

      case 'is-not-allowed':
        setShowAddEditModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(<FormattedMessage id="adminPermission" />);
        setShowStatusModal(true);
        break;

      case 'campaign-associated-with-a-qrcode':
        setShowAddEditModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(
          <FormattedMessage id="campaignAssociatedWithQrcode" />,
        );
        setShowStatusModal(true);
        break;

      case 'delete-success':
        setResponseStatus('success');
        setShowDeleteModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="campaignDeleteSuccess" />);
        break;

      case 'create-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="campaignAddSuccess" />);
        break;

      case 'update-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="campaignEditSuccess" />);
        break;

      default:
        break;
    }
  }, [requestStatusType]);

  // Modal Contents

  const addCampaignModalForm = (
    <AddEditSlugForm
      name="Campaign"
      errorMessage={errorMessage}
      data={editData}
      dispatchAdd={campaignActions.campaignCreateRequest}
      dispatchEdit={campaignActions.campaignUpdateRequest}
    />
  );

  useEffect(() => {
    dispatch(campaignActions.campaignRequest(1));
    if (status) {
      setRequestStatusType(status);
    }
    statusMethod();
  }, [dispatch, status]);

  return (
    <>
      <Loader loading={isLoading} />
      <Modal
        title={
          editData ? (
            <FormattedMessage id="editCampaign" />
          ) : (
            <FormattedMessage id="editCampaign" />
          )
        }
        setIsOpen={setShowAddEditModal}
        isOpen={showAddEditModal}
        content={addCampaignModalForm}
        loading={isLoading}
      />
      <ModalStatus
        status={responseStatus}
        setIsOpen={setShowStatusModal}
        isOpen={showStatusModal}
        text={confirmationMessage}
      />
      <ModalConfirmation
        title={<FormattedMessage id="areYouSure" />}
        buttonName={<FormattedMessage id="delete" />}
        text={<FormattedMessage id="campaignDeleteConfirmMessage" />}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        loading={isLoading}
        confirmOnClick={() => {
          dispatch(campaignActions.campaignDeleteRequest(campaignID));
          setConfirmationMessage(
            <FormattedMessage id="campaignDeleteSuccess" />,
          );
        }}
      />
      <Header
        title="Campaign"
        addButtonName={<FormattedMessage id="add" />}
        filterBtn
        onClickAdd={handleAddCampaign}
        onClickFilter={() => setShowFilter(1)}
      />

      <ContentWrapper>
        <FilterBar
          display={showFilter}
          onClickClose={() => setShowFilter(0)}
          dateDispatch={campaignActions.campaignFilterByDateRequest}
          campaignDispatch={campaignActions.campaignFilterByNameRequest}
          allListDispatch={campaignActions.campaignRequest(1)}
        />
        <Table>
          <TableHead>
            <Tr>
              {headers.map((header, index) => (
                <Th key={index}>
                  {header}
                  {header && (
                    <SortButton
                      onClick={() =>
                        sortData(campaignData, header, ascDesc, setAscDesc)
                      }
                    />
                  )}
                </Th>
              ))}
            </Tr>
          </TableHead>
          <TableBody>
            {campaignData &&
              campaignData.map((campaign) => (
                <Tr key={campaign.id}>
                  <Td>{campaign.name}</Td>
                  <Td>{campaign.slug}</Td>
                  <Td>
                    {moment(campaign.createdAt).utc().format('DD/MM/yyyy')}
                  </Td>
                  <Td>
                    {moment(campaign.updatedAt).utc().format('DD/MM/yyyy')}
                  </Td>
                  <Td>
                    <TableButtonsWrapper>
                      <Button
                        width="32px"
                        height="32px"
                        icon={editIcon}
                        onClick={() => handleEditCampaign(campaign)}
                      />
                      <Button
                        width="32px"
                        height="32px"
                        icon={trashIcon}
                        onClick={() => handleModalDelete(campaign.id)}
                      />
                    </TableButtonsWrapper>
                  </Td>
                </Tr>
              ))}
          </TableBody>
        </Table>
        {pagination && (
          <Paginate
            totalPages={pagination.totalPages}
            currentPage={currentPage}
            onClick={(e) => handlePagination(e)}
            onClickPrev={handlePaginationPrev}
            onClickNext={handlePaginationNext}
          />
        )}
      </ContentWrapper>
    </>
  );
};

export default CampaignList;
