import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import SpanError from 'components/core/SpanError';
import { Container, Label } from './styles';

const Select = ({
  type,
  id,
  name,
  value,
  onChange,
  isError,
  error,
  children,
  disabled,
  options,
  secondary,
}) => (
  <Container isError={isError} secondary={secondary}>
    <Label htmlFor={name} isError={isError} secondary={secondary}>
      {children}
    </Label>
    <Form.Group>
      <Form.Control
        as="select"
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        error={error}
        disabled={disabled}
      >
        <option key="selecionar" value="" disabled>
          Selecionar
        </option>
        {options &&
          options.map((option, index) => (
            <option value={option.value || option.id} key={index}>
              {option.name}
            </option>
          ))}
      </Form.Control>
    </Form.Group>
    {isError && <SpanError>{error}</SpanError>}
  </Container>
);

Select.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isError: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
  ),
  secondary: PropTypes.bool,
};

Select.defaultProps = {
  type: '',
  id: '',
  name: '',
  value: '',
  onChange: () => {},
  isError: false,
  disabled: false,
  secondary: false,
  options: [{}],
};

export default Select;
