import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;

  img {
    margin-bottom: 20px;
  }

  p {
    font-family: 'Suvinil Sans';
    font-size: 20px;
    font-weight: bold;
  }
`;
