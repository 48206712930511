import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  authUserRequest: ['payload'],
  authUserSuccess: ['formations'],
  authUserFail: ['error'],
});

const INITIAL_STATE = {
  formations: [],
  error: {},
  isLoading: 0,
};
const authUserRequest = (state) => ({ ...state, isLoading: 1 });
const authUserSuccess = (state, { formations }) => ({
  ...state,
  isLoading: 0,
  formations,
});
const authUserFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.AUTH_USER_REQUEST]: authUserRequest,
  [Types.AUTH_USER_SUCCESS]: authUserSuccess,
  [Types.AUTH_USER_FAIL]: authUserFail,
});
