import { takeLatest, put } from 'redux-saga/effects';
import { Types } from 'store/ducks/qrCodesReducer';
import { GET, POST, PUT, DELETE } from 'config/constants/verbs';
import API from 'utils/API';
import { QRCODES_BASE, QRCODES_REDIRECT } from 'config/constants/endPoints';

function* requestListQrCodes({ page }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${QRCODES_BASE}/?page=${page || 1}`,
    });
    yield put({
      type: Types.QR_CODES_LIST_SUCCESS,
      isLoading: 0,
      data: data.data,
      pagination: data.metadata,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_LIST_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

function* requestListQrCodesFilteredByDate({ startDate, endDate }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${QRCODES_BASE}?startDate=${startDate}T00:00:00.000Z&endDate=${endDate}T23:59:59.000Z`,
    });

    yield put({
      type: Types.QR_CODES_LIST_SUCCESS,
      isLoading: 0,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_FILTER_BY_DATE_LIST_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

function* requestListQrCodesFilteredByCampaign({ campaign }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${QRCODES_BASE}?nameCampaign=${campaign.name}`,
    });

    yield put({
      type: Types.QR_CODES_LIST_SUCCESS,
      isLoading: 0,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_FILTER_BY_CAMPAIGN_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

function* requestListQrCodesFilteredByMedium({ medium }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${QRCODES_BASE}?nameMedium=${medium.name}`,
    });

    yield put({
      type: Types.QR_CODES_LIST_SUCCESS,
      isLoading: 0,
      data: data.data,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_FILTER_BY_MEDIUM_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

function* requestCreateQrCode({ payload }) {
  const { name, slug, redirectURL, medium, campaign } = payload;

  try {
    yield API.requestServer({
      verb: POST,
      endpoint: QRCODES_BASE,
      data: {
        name,
        slug,
        urlRedirect: redirectURL,
        mediumId: medium,
        campaignId: campaign,
      },
    });

    yield put({
      type: Types.QR_CODES_LIST_REQUEST,
    });
    yield put({
      type: Types.QR_CODES_CREATE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_CREATE_FAIL,
      error: error.response,
    });
  }
}

function* requestUpdateQrCode({ payload, id }) {
  const { name, slug, redirectURL, medium, campaign } = payload;

  try {
    yield API.requestServer({
      verb: PUT,
      endpoint: `${QRCODES_BASE}/${id}`,
      data: {
        name,
        slug,
        urlRedirect: redirectURL,
        mediumId: medium,
        campaignId: campaign,
      },
    });

    yield put({
      type: Types.QR_CODES_LIST_REQUEST,
    });
    yield put({
      type: Types.QR_CODES_UPDATE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_UPDATE_FAIL,
      error: error.response,
    });
  }
}

function* requestDeleteQrCode({ id }) {
  try {
    yield API.requestServer({
      verb: DELETE,
      endpoint: `${QRCODES_BASE}/${id}`,
    });

    yield put({
      type: Types.QR_CODES_LIST_REQUEST,
    });
    yield put({
      type: Types.QR_CODES_DELETE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODES_UPDATE_FAIL,
      error: error.response,
    });
  }
}

function* requestRedirectQrCode({ payload }) {
  try {
    const { data } = yield API.requestServer({
      verb: GET,
      endpoint: `${QRCODES_REDIRECT}/${payload}`,
    });
    yield put({
      type: Types.QR_CODE_REDIRECT_SUCCESS,
      isLoading: 0,
      qrcode: data,
    });
  } catch (error) {
    yield put({
      type: Types.QR_CODE_REDIRECT_FAIL,
      isLoading: 0,
      message: error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.QR_CODES_LIST_REQUEST, requestListQrCodes);
  yield takeLatest(Types.QR_CODES_CREATE_REQUEST, requestCreateQrCode);
  yield takeLatest(Types.QR_CODES_UPDATE_REQUEST, requestUpdateQrCode);
  yield takeLatest(Types.QR_CODES_DELETE_REQUEST, requestDeleteQrCode);
  yield takeLatest(
    Types.QR_CODES_FILTER_BY_DATE_LIST_REQUEST,
    requestListQrCodesFilteredByDate,
  );
  yield takeLatest(
    Types.QR_CODES_FILTER_BY_CAMPAIGN_REQUEST,
    requestListQrCodesFilteredByCampaign,
  );
  yield takeLatest(
    Types.QR_CODES_FILTER_BY_MEDIUM_REQUEST,
    requestListQrCodesFilteredByMedium,
  );
  yield takeLatest(Types.QR_CODE_REDIRECT_REQUEST, requestRedirectQrCode);
}
