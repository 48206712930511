import dispatchEvent from 'helpers/dispatchDataLayer';

const sidebar = {
  qrCodeListPage: () =>
    dispatchEvent({
      event: 'interaction',
      event_category: 'sidebar:qrcode-list-page',
      event_action: 'qrcode-page',
      event_label: 'go-to-create-qrcode-list-page',
    }),
  exit: () =>
    dispatchEvent({
      event: 'interaction',
      event_category: 'sidebar:exit',
      event_action: 'exit',
      event_label: 'exit-application',
    }),
};

export default sidebar;
