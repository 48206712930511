import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  sendEmailRequest: ['payload'],
  sendEmailSuccess: ['payload'],
  sendEmailFail: ['error'],

  sendNewPasswordRequest: ['password', 'token'],
  sendNewPasswordSuccess: ['payload'],
  sendNewPasswordFail: ['error'],
});

const INITIAL_STATE = {
  data: [],
  isLoading: 0,
  message: '',
  email: '',
  error: {},
};

const sendEmailRequest = () => ({ isLoading: 1 });

const sendEmailSuccess = (state, payload) => ({
  ...state,
  isLoading: 0,
  email: payload.email,
});

const sendEmailFail = (state) => ({
  ...state,
  isLoading: 0,
  message: 'Email não cadastrado',
});

const sendNewPasswordRequest = (state) => ({
  ...state,
  isLoading: 1,
});

const sendNewPasswordSuccess = (state) => ({
  ...state,
  isLoading: 0,
});

const sendNewPasswordFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.SEND_EMAIL_REQUEST]: sendEmailRequest,
  [Types.SEND_EMAIL_SUCCESS]: sendEmailSuccess,
  [Types.SEND_EMAIL_FAIL]: sendEmailFail,

  [Types.SEND_NEW_PASSWORD_REQUEST]: sendNewPasswordRequest,
  [Types.SEND_NEW_PASSWORD_SUCCESS]: sendNewPasswordSuccess,
  [Types.SEND_NEW_PASSWORD_FAIL]: sendNewPasswordFail,
});
