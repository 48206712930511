import styled from 'styled-components';
import Button from 'components/core/Button';
import theme from 'theme';

const { defaultColor } = theme.colors;

export const Container = styled.div`
  padding: 50px 40px 12px 30px;
  height: 123px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h3`
  font-family: 'Suvinil Sans';
  font-weight: bold;
  font-size: 24px;
  margin: 0;
`;

export const FilterButton = styled(Button)`
  margin: 0 20px 4px 0;
  background: transparent;
  color: ${defaultColor};
  font-size: 16px;

  :hover {
    background: none;
  }
`;

export const AddButton = styled(Button)`
  padding: 8px 16px;
`;
