import styled from 'styled-components';
import theme from 'theme/';

export const Container = styled.div`
  background-color: ${theme.colors.thirdGray};
  height: 100vh;
  position: fixed;
  width: 295px;
`;

export const Content = styled.div`
  padding: 20px 0;

  a {
    text-decoration: none;
  }

  .active {
    border-left: 4.35px solid ${theme.colors.black};
    background: linear-gradient(
      90.04deg,
      rgba(0, 0, 0, 0.1) 22.31%,
      rgba(0, 0, 0, 0) 109.16%
    );
    font-weight: 600;
  }
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 0 17px 0 38px;
  color: ${theme.colors.black};
  height: 48px;
  transition: border 0.2s ease-out;
  text-decoration: none;
  cursor: pointer;
  ${({ active }) => active && `border-left: 5px solid ${theme.colors.black};`};

  > img {
    margin-right: 16px;
  }

  &:hover {
    color: ${theme.colors.darkGray};
    border-left: 4.35px solid ${theme.colors.black};
    background: linear-gradient(
      90.04deg,
      rgba(0, 0, 0, 0.1) 22.31%,
      rgba(0, 0, 0, 0) 109.16%
    );
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 120px;

  > img {
    width: 156px;
  }
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid;

  border-image-source: linear-gradient(
    90.04deg,
    rgba(0, 0, 0, 0.1) 22.31%,
    rgba(0, 0, 0, 0) 109.16%
  );
  border-image-slice: 1;
`;
