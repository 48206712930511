import styled from 'styled-components';
import Button from 'components/core/Button';
import theme from 'theme';

const { defaultColor } = theme.colors;

export const Container = styled.div`
  display: ${({ display }) => (display === 0 ? 'none' : 'flex')};
  align-items: center;
  margin-bottom: 15px;

  .form-group {
    width: 160px;
  }

  form {
    width: 100%;
    display: flex;
    align-items: center;

    div:first-child {
      width: inherit;
    }

    button {
      margin-right: 20px;
    }
  }
`;

export const FilterHideButton = styled(Button)`
  margin: 5px 0 0 16px;
  background: transparent;
  color: ${defaultColor};
  font-size: 12px;

  :hover {
    background: none;
    color: #b54b00;
  }
`;

export const FilterClearButton = styled(Button)`
  color: ${defaultColor};
  margin: 4px 0 0 8px;
  padding: 8px 16px;
  font-size: 14px;
  border: 1px solid ${defaultColor};
  background: transparent;

  :hover {
    color: #b54b00;
    border: 1px solid #b54b00;
    background: transparent;
  }
`;

export const FilterButton = styled(Button)`
  margin: 4px 0 0 8px;
  padding: 8px 16px;
  font-size: 14px;
`;
