const theme = {
  colors: {
    white: '#fff',
    gray: '#f2f2f2',
    borderGray: '#dedede',
    darkGray: '#606060',
    secondDarkGray: '#666666',
    defaultInput: '#f7f7f7',
    defaultColor: '#ff6900',
    defaultHoverButton: '#e25d00',
    defaultHoverTable: '#ff690080',
    defaultBoxShadow: 'rgba(0, 0, 0, 0.1)',
    error: '#f02000',
    danger: '#e33d3d',
    dangerHover: '#b93030',
    lightError: '#ffe6e2',
    secondary: '#efebeb',
    black: '#333333',
    secondGray: '#efefef',
    thirdGray: '#e5e5e5',
  },
  font: {
    fontSizeDefault: '16px',
  },
  validations: {
    imageRequired: 'Imagem é obrigatória.',
    fieldRequired: 'Campo é obrigatório.',
  },
};

export default theme;
