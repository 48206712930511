import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import filterIcon from 'assets/icons/filter.svg';
import addIcon from 'assets/icons/add.svg';
import { Container, Title, Content, FilterButton, AddButton } from './styles';

const ContentHeader = ({
  title,
  addButtonName,
  onClickAdd,
  onClickFilter,
  filterBtn,
}) => (
  <Container>
    <Title>{title}</Title>
    <Content>
      {filterBtn && (
        <FilterButton
          icon={filterIcon}
          btnType="primary"
          borderRadius="8px"
          height="34px"
          fontFamily="Montserrat"
          onClick={onClickFilter}
        >
          <FormattedMessage id="filter" />
        </FilterButton>
      )}
      <AddButton
        icon={addIcon}
        btnType="primary"
        borderRadius="8px"
        width="100%"
        height="44px"
        fontFamily="Trebuchet MS"
        onClick={onClickAdd}
      >
        {addButtonName}
      </AddButton>
    </Content>
  </Container>
);

export default ContentHeader;

ContentHeader.propTypes = {
  title: PropTypes.node,
  addButtonName: PropTypes.node,
  onClickAdd: PropTypes.func,
  onClickFilter: PropTypes.func,
  filterBtn: PropTypes.bool,
};

ContentHeader.defaultProps = {
  title: null,
  addButtonName: null,
  onClickAdd: () => {},
  onClickFilter: () => {},
  filterBtn: false,
};
