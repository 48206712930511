import { createGlobalStyle } from 'styled-components';
import Suvinilbook from 'assets/fonts/SuvinilSans-Book.ttf';
import Suvinilbold from 'assets/fonts/SuvinilSans-Bold.ttf';
import Trebuchet from 'assets/fonts/Trebuchet_MS.ttf';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,300&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

  *{
    margin: 0;
    padding: 0;
    outline: none !important;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }

  body{
    -webkit-font-smoothing: antialiased !important;
  }

  a{
    text-decoration: none !important;
    color: inherit;
  }

  html{
    scroll-behavior: smooth;
  }

  body,
  p {
    font-family: "Inter", sans-serif;
  }

  @font-face {
    font-family: 'Suvinil Sans';
    src: url(${Suvinilbook}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Suvinil Sans';
    src: url(${Suvinilbold}) format("truetype");
    font-weight: bold;
    font-style: normal;
  }


  @font-face {
    font-family: 'Trebuchet MS';
    src: url(${Trebuchet}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }

`;
