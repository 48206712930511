import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Select from 'components/core/Select';
import DateInput from 'components/core/DateInput';
import FormInput from 'components/core/FormInput';
import closeIcon from 'assets/icons/close-primary.svg';
import { Creators as campaignActions } from 'store/ducks/campaignReducer';
import { Creators as mediumActions } from 'store/ducks/mediumReducer';
import { Creators as usersActions } from 'store/ducks/usersReducer';
import roles from 'utils/data/roles.json';
import {
  Container,
  FilterHideButton,
  FilterClearButton,
  FilterButton,
} from './styles';

const FilterBar = ({
  children,
  display,
  onClickClose,
  campaignDispatch,
  mediumDispatch,
  dateDispatch,
  allListDispatch,
  user,
}) => {
  // eslint-disable-next-line global-require
  const { format } = require('date-fns');
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [campaignValue, setCampaignValue] = useState();
  const [mediumValue, setMediumValue] = useState();
  const [nameEmail, setNameEmail] = useState('');
  const [role, setRole] = useState('');
  const [mediumArray, setMediumArray] = useState([]);
  const [campaignArray, setCampaignArray] = useState([]);
  const { campaignSelectData, campaignRequestSuccess, deleteCampaignSuccess } =
    useSelector(({ campaignReducer }) => campaignReducer);
  const { mediumSelectData, mediumRequestSuccess, deleteMediumSuccess } =
    useSelector(({ mediumReducer }) => mediumReducer);

  const handleCampaignValue = useCallback(
    (e) => {
      setCampaignValue(e.currentTarget.value);
      const campaign = campaignArray.find(
        (element) => element.id === e.currentTarget.value,
      );
      dispatch(campaignDispatch(campaign));
    },
    [campaignArray, setCampaignValue],
  );

  const handleMediumValue = useCallback(
    (e) => {
      setMediumValue(e.currentTarget.value);
      const medium = mediumArray.find(
        (element) => element.id === e.currentTarget.value,
      );
      dispatch(mediumDispatch(medium));
    },
    [mediumArray, setMediumValue],
  );

  const handleFilterByRole = useCallback(
    (e) => {
      const index = e.nativeEvent.target.selectedIndex;
      const roleText = e.nativeEvent.target[index].text;
      const { value } = e.currentTarget;
      setRole(value);
      dispatch(usersActions.usersFilterByRoleRequest(roleText.toLowerCase()));
    },
    [setRole],
  );

  const handleNameEmailSearch = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(usersActions.usersFilterBySearchRequest(nameEmail));
    },
    [nameEmail],
  );

  const clearFilter = useCallback(() => {
    if (
      startDate ||
      endDate ||
      campaignValue ||
      mediumValue ||
      nameEmail ||
      role
    ) {
      setStartDate(undefined);
      setEndDate(undefined);
      setCampaignValue('');
      setMediumValue('');
      setNameEmail('');
      setRole('');
      dispatch(allListDispatch);
    }
  }, [startDate, endDate, campaignValue, mediumValue, nameEmail, role]);

  useEffect(() => {
    dispatch(campaignActions.campaignForSelectListRequest());
    dispatch(mediumActions.mediumForSelectListRequest());
  }, [dispatch]);

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        dateDispatch(
          format(startDate, 'yyyy-MM-dd'),
          format(endDate, 'yyyy-MM-dd'),
        ),
      );
    }

    if (display === 0) {
      clearFilter();
    }

    if (mediumSelectData) {
      setMediumArray(mediumSelectData);
    }

    if (campaignSelectData) {
      setCampaignArray(campaignSelectData);
    }

    if (campaignRequestSuccess || deleteCampaignSuccess) {
      dispatch(campaignActions.campaignForSelectListRequest());
    }

    if (mediumRequestSuccess || deleteMediumSuccess) {
      dispatch(mediumActions.mediumForSelectListRequest());
    }
  }, [
    dispatch,
    startDate,
    endDate,
    format,
    display,
    mediumSelectData,
    campaignSelectData,
    campaignRequestSuccess,
    deleteCampaignSuccess,
    mediumRequestSuccess,
    deleteMediumSuccess,
  ]);

  return (
    <Container display={display}>
      {children}
      {user && (
        <form>
          <FormInput
            secondary
            type="name"
            id="name"
            name="name"
            value={nameEmail}
            onChange={(e) => setNameEmail(e.currentTarget.value)}
          >
            <FormattedMessage id="nameOrEmail" />
          </FormInput>
          <FilterButton
            type="submit"
            btnType="primary"
            fontFamily="Montserrat"
            height="38px"
            borderRadius="8px"
            onClick={handleNameEmailSearch}
          >
            <FormattedMessage id="search" />
          </FilterButton>
          <Select
            secondary
            type="role"
            id="role"
            name="role"
            value={role}
            onChange={handleFilterByRole}
            options={roles.roles}
          >
            <FormattedMessage id="role" />
          </Select>
        </form>
      )}
      {dateDispatch && (
        <>
          <DateInput
            label={<FormattedMessage id="dateFrom" />}
            selectsStart
            startDate={startDate}
            selected={startDate}
            onChange={setStartDate}
          />
          <DateInput
            label={<FormattedMessage id="dateTo" />}
            selectsEnd
            endDate={endDate}
            selected={endDate}
            onChange={setEndDate}
          />
        </>
      )}
      {campaignDispatch && (
        <Select
          secondary
          type="campaign"
          id="campaign"
          name="campaign"
          value={campaignValue}
          onChange={handleCampaignValue}
          options={campaignArray}
        >
          Campaign
        </Select>
      )}
      {mediumDispatch && (
        <Select
          secondary
          type="medium"
          id="medium"
          name="medium"
          value={mediumValue}
          onChange={handleMediumValue}
          options={mediumArray}
        >
          Medium
        </Select>
      )}

      <FilterClearButton
        btnType="primary"
        fontFamily="Montserrat"
        height="38px"
        borderRadius="8px"
        onClick={clearFilter}
      >
        <FormattedMessage id="clearFilter" />
      </FilterClearButton>

      <FilterHideButton
        icon={closeIcon}
        fontFamily="Montserrat"
        height="20px"
        onClick={onClickClose}
      >
        <FormattedMessage id="hideFilter" />
      </FilterHideButton>
    </Container>
  );
};

FilterBar.propTypes = {
  children: PropTypes.node,
  onClickClose: PropTypes.func.isRequired,
  display: PropTypes.number,
  campaignDispatch: PropTypes.func,
  mediumDispatch: PropTypes.func,
  dateDispatch: PropTypes.func,
  user: PropTypes.bool,
};

FilterBar.defaultProps = {
  children: undefined,
  display: 0,
  campaignDispatch: undefined,
  mediumDispatch: undefined,
  dateDispatch: undefined,
  user: false,
};

export default FilterBar;
