import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  qrCodesListRequest: ['page'],
  qrCodesListSuccess: ['qrcodes'],
  qrCodesListFail: ['error'],

  qrCodesCreateRequest: ['payload'],
  qrCodesCreateSuccess: [],
  qrCodesCreateFail: ['error'],

  qrCodesUpdateRequest: ['payload', 'id'],
  qrCodesUpdateSuccess: [],
  qrCodesUpdateFail: ['error'],

  qrCodesDeleteRequest: ['id'],
  qrCodesDeleteSuccess: [],
  qrCodesDeleteFail: ['error'],

  qrCodesFilterByDateListRequest: ['startDate', 'endDate'],
  qrCodesFilterByDateListSuccess: ['qrcodes'],
  qrCodesFilterByDateListFail: ['error'],

  qrCodesFilterByCampaignRequest: ['campaign'],
  qrCodesFilterByCampaignSuccess: ['qrcodes'],
  qrCodesFilterByCampaignFail: ['error'],

  qrCodesFilterByMediumRequest: ['medium'],
  qrCodesFilterByMediumSuccess: ['qrcodes'],
  qrCodesFilterByMediumFail: ['error'],

  qrCodeRedirectRequest: ['payload'],
  qrCodeRedirectSuccess: ['qrcode'],
  qrCodeRedirectFail: ['error'],
});

const INITIAL_STATE = {
  data: [],
  createQrCodeSuccess: false,
  updateQrCodeSuccess: false,
  deleteQrCodeSuccess: false,
  responseData: [],
  status: '',
  pagination: [],
  error: '',
};

// LIST

const qrCodesListRequest = (state) => ({ ...state, isLoading: 1 });
const qrCodesListSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  data,
  pagination,
  status: '',
});
const qrCodesListFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// CREATE

const qrCodesCreateRequest = () => ({ isLoading: 1 });
const qrCodesCreateSuccess = (state, { qrcode }) => ({
  ...state,
  isLoading: 0,
  responseData: qrcode,
  status: 'create-success',
});
const qrCodesCreateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// UPDATE

const qrCodesUpdateRequest = () => ({ isLoading: 1 });
const qrCodesUpdateSuccess = (state, { qrcode }) => ({
  ...state,
  isLoading: 0,
  data: qrcode,
  status: 'update-success',
});
const qrCodesUpdateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// DELETE

const qrCodesDeleteRequest = () => ({ isLoading: 1 });
const qrCodesDeleteSuccess = (state) => ({
  ...state,
  isLoading: 0,
  status: 'delete-success',
});
const qrCodesDeleteFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// FILTER BY DATE

const qrCodesFilterByDateListRequest = (state) => ({ ...state, isLoading: 1 });
const qrCodesFilterByDateListSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  data,
  pagination,
});
const qrCodesFilterByDateListFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// FILTER BY CAMPAIGN

const qrCodesFilterByCampaignRequest = (state) => ({ ...state, isLoading: 1 });
const qrCodesFilterByCampaignSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  data,
  pagination,
});
const qrCodesFilterByCampaignFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// FILTER BY CAMPAIGN

const qrCodesFilterByMediumRequest = (state) => ({ ...state, isLoading: 1 });
const qrCodesFilterByMediumSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  data,
  pagination,
});
const qrCodesFilterByMediumFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// REDIRECT

const qrCodeRedirectRequest = (slug) => ({ isLoading: 1, slug });
const qrCodeRedirectuccess = (state, { qrcode }) => ({
  ...state,
  isLoading: 0,
  qrcode,
});
const qrCodeRedirectFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.QR_CODES_LIST_REQUEST]: qrCodesListRequest,
  [Types.QR_CODES_LIST_SUCCESS]: qrCodesListSuccess,
  [Types.QR_CODES_LIST_FAIL]: qrCodesListFail,

  [Types.QR_CODES_CREATE_REQUEST]: qrCodesCreateRequest,
  [Types.QR_CODES_CREATE_SUCCESS]: qrCodesCreateSuccess,
  [Types.QR_CODES_CREATE_FAIL]: qrCodesCreateFail,

  [Types.QR_CODES_UPDATE_REQUEST]: qrCodesUpdateRequest,
  [Types.QR_CODES_UPDATE_SUCCESS]: qrCodesUpdateSuccess,
  [Types.QR_CODES_UPDATE_FAIL]: qrCodesUpdateFail,

  [Types.QR_CODES_DELETE_REQUEST]: qrCodesDeleteRequest,
  [Types.QR_CODES_DELETE_SUCCESS]: qrCodesDeleteSuccess,
  [Types.QR_CODES_DELETE_FAIL]: qrCodesDeleteFail,

  [Types.QR_CODES_FILTER_BY_DATE_LIST_REQUEST]: qrCodesFilterByDateListRequest,
  [Types.QR_CODES_FILTER_BY_DATE_LIST_SUCCESS]: qrCodesFilterByDateListSuccess,
  [Types.QR_CODES_FILTER_BY_DATE_LIST_FAIL]: qrCodesFilterByDateListFail,

  [Types.QR_CODES_FILTER_BY_CAMPAIGN_REQUEST]: qrCodesFilterByCampaignRequest,
  [Types.QR_CODES_FILTER_BY_CAMPAIGN_SUCCESS]: qrCodesFilterByCampaignSuccess,
  [Types.QR_CODES_FILTER_BY_CAMPAIGN_FAIL]: qrCodesFilterByCampaignFail,

  [Types.QR_CODES_FILTER_BY_MEDIUM_REQUEST]: qrCodesFilterByMediumRequest,
  [Types.QR_CODES_FILTER_BY_MEDIUM_SUCCESS]: qrCodesFilterByMediumSuccess,
  [Types.QR_CODES_FILTER_BY_MEDIUM_FAIL]: qrCodesFilterByMediumFail,

  [Types.QR_CODE_REDIRECT_REQUEST]: qrCodeRedirectRequest,
  [Types.QR_CODE_REDIRECT_SUCCESS]: qrCodeRedirectuccess,
  [Types.QR_CODE_REDIRECT_FAIL]: qrCodeRedirectFail,
});
