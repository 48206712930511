import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 400ms ease-in;
  z-index: 999;
`;

export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 250px;
  max-width: 100%;
  min-height: 250px;
  max-height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  margin: auto;
  background-color: white;
  border-radius: 8px;
  padding: 50px 24px 24px;
  box-sizing: border-box;
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ titleRight }) =>
    titleRight ? 'flex-start' : 'center'};
  padding-bottom: 15px;
  color: black;

  button {
    position: absolute;
    top: 15px;
    right: 15px;
    > img {
      margin: 0;
    }
  }
`;

export const Title = styled.h3`
  font-family: 'Suvinil Sans';
  font-size: ${({ titleSize }) => (titleSize ? `${titleSize}` : '24px')};
  font-weight: bold;
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
