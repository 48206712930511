export default {
  'pt-BR': {
    login: 'Entrar',
    create: 'Criar',
    emailRequired: 'E-mail*',
    email: 'E-mail',
    passwordRequired: 'Senha*',
    dataBaseName: 'Nome da base de metadados',
    description: 'Descrição',
    addQrCode: 'Criar um novo QrCode',
    name: 'Nome',
    nameRequired: 'Nome*',
    redirect: 'Redirecionamento',
    link: 'Link',
    qrCode: 'qrCode',
    validations: {
      name: 'Nome é obrigátorio',
      redirect: 'Redirecionamento é obrigátorio',
      qrcode: 'qrcode é obrigátorio',
    },
    titleApplication: 'QR Codes suvinil',
    forgotPassword: 'Esqueci minha senha',
    continue: 'Continuar',
    goToLogin: 'Ir para login',
    newPassword: 'Nova senha',
    confirmPassword: 'Confirmar senha',
    redefinePassword: 'Redefinir senha',
    resetPassword: 'Redefinição de senha',
    resetPasswordDescription:
      'Para redefinir sua senha, digite o e-mail que você utilizou no cadastrado para administrador.',
    emailSent: 'E-mail enviado',
    createNewPasswordDescription:
      'Crie uma nova senha abaixo. Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número e, no mínimo, 6 caracteres.',
    passwordCreated: 'Senha redefinida com sucesso!',
    unfilledEmail: 'Email não preenchido',
    unfilledPassword: 'Senha não preenchida',
    passwordFormatAlert:
      'Confira as exigências necessárias para a sua nova senha',
    passwordMustToBeSame: 'As senhas devem ser iguais',
    newPasswordCreationError:
      'Um erro ocorreu ao criar sua nova senha. Tente novamente!',
    invalidCredentials: 'Usuário ou senha inválidos',
    addUser: 'Adicionar usuário',
    unfilledName: 'Nome não preenchido',
    unfilledRole: 'Role não foi selecionado',
    unfilledRedirection: 'Redirecionamento não preenchido',
    unfilledSlug: 'Slug não preenchido',
    unfilledLink: 'Link não preenchido',
    unfilledCampaign: 'Campaign não selecionado',
    unfilledMedium: 'Medium não selecionado',
    redirectionRequired: 'Redirecionamento*',
    slugRequired: 'Slug*',
    linkRequired: 'Link*',
    campaignRequired: 'Campaign*',
    mediumRequired: 'Medium*',
    add: 'Adicionar',
    filter: 'Filtrar',
    qrcodeRegistered:
      'QR code já cadastrado. Verifique se o nome ou slug já existem.',
    adminPermission:
      'Você precisa ter permissão de Admin para executar essa ação',
    qrcodeAddSuccess: 'QR code adicionado com sucesso!',
    qrcodeEditSuccess: 'QR code editado com sucesso!',
    qrcodeDeleteSuccess: 'QR code excluído com sucesso!',
    areYouSure: 'Tem certeza?',
    delete: 'DELETAR',
    qrcodeDeleteConfirmMessage:
      'Ao pressionar deletar esse QR code será apagado da planilha junto com seus dados.',
    editQrcode: 'Editar QR Code',
    addQrcode: 'Adicionar QR Code',
    cancel: 'CANCELAR',
    slugExample: 'exemplo: tintas-suvinil-exemplo',
    enterFullname: 'Digite o nome completo',
    enterRedirect: 'Digite o redirecionamento',
    urlRedirectLabel: 'URL do redirecionamento',
    edit: 'Editar',
    close: 'Fechar',
    downloadQrcode: 'Baixar QR code',
    qrcodeLinkLabel: 'Link QR code',
    qrcodeFinalUrl: 'URL destino final',
    nameOrSlugRegistered: 'Nome ou slug já cadastrado',
    mediumAssociatedWithQrcode: 'Este Medium está associado a um QR code',
    mediumDeleteSuccess: 'Medium excluído com sucesso!',
    mediumAddSuccess: 'Medium adicionado com sucesso!',
    mediumEditSuccess: 'Medium editado com sucesso!',
    editMedium: 'Editar Medium',
    addMedium: 'Adicionar novo Medium',
    mediumDeleteConfirmMessage:
      'Ao pressionar deletar esse Medium será apagado da planilha junto com seus dados.',
    campaignAssociatedWithQrcode: 'Este Campaign está associado a um QR code',
    campaignDeleteSuccess: 'Campaign excluído com sucesso!',
    campaignAddSuccess: 'Campaign adicionado com sucesso!',
    campaignEditSuccess: 'Campaign editado com sucesso!',
    editCampaign: 'Editar Campaign',
    addCampaign: 'Adicionar novo Campaign',
    campaignDeleteConfirmMessage:
      'Ao pressionar deletar esse Campaign será apagado da planilha junto com seus dados.',
    emailAlreadyRegistered: 'Email já cadastrado',
    userDeleteSuccess: 'Usuário excluído com sucesso!',
    userAddSuccess: 'Usuário adicionado com sucesso!',
    userEditSuccess: 'Usuário editado com sucesso!',
    editUser: 'Editar Usuário',
    addNewUser: 'Adicionar novo Usuário',
    userDeleteConfirmMessage:
      'Ao pressionar deletar esse usuário será apagado da planilha junto com seus dados.',
    users: 'Usuários',
    fieldRequired: 'Campo obrigatório',
    userPasswordCreationMessage:
      'Sua senha deve conter letra maiúscula, letra minúscula, caractere especial, número, mínimo de 6 e máximo de 16 caracteres.',
    enterPassword: 'Digite uma senha',
    linkCopied: 'Link copiado!',
    copy: 'Copiar',
    nameOrEmail: 'Nome ou Email',
    search: 'Procurar',
    role: 'Permissão',
    dateFrom: 'Data de',
    dateTo: 'Data até',
    clearFilter: 'Limpar filtros',
    hideFilter: 'Fechar filtros',
    creationDate: 'Data de criação',
    view: 'Leituras',
    lastEditDate: 'Data da última edição',
    slug: 'Slug',
    invalidUserOrPassword: 'Ops, usuário ou senha inválidos',
    invalidSlug: 'Slug inválido',
  },
};
