import styled, { css } from 'styled-components';
import theme from 'theme/';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 15px;

  > svg {
    position: absolute;
    right: 10px;
    font-size: 20px;
  }

  label {
    margin-bottom: 0;
  }

  .show-password {
    position: absolute;
    background: transparent;
    padding: 0;
    margin: 0;
    top: 21px;
  }

  .error-icon {
    position: absolute;
    width: 20px;
    top: 36px;
    right: 14px;
  }
`;

export const Label = styled.label`
  color: ${theme.colors.darkGray};
  font-weight: 400;
  font-size: 14px;

  ${({ isError }) => isError && `color: ${theme.colors.error}`}

  ${({ secondary }) =>
    secondary &&
    css`
      font-family: 'Montserrat';
    `};
`;

export const InputStyle = styled.input`
  position: relative;
  margin: 0;
  outline: 0;
  line-height: 1.21em;
  padding: 0.67em 1em;
  font-size: 1em;
  background: ${theme.colors.defaultInput};
  border: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 5px;
  box-shadow: inset 0 0 0 0 transparent;
  transition: color 0.1s ease, border-color 0.1s ease;
  height: 48px;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : '')};

  ${({ isError }) => isError && `border: 1px solid ${theme.colors.error}`}

  ${({ secondary }) =>
    secondary &&
    css`
      font-size: 12px;
      font-family: 'Montserrat';
      height: 38px;
      border: 1px solid #ecedf1;
      border-radius: 8px;
      background: #fff;
    `};
`;
