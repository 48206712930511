import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from 'components/core/Button';
import Header from 'components/core/ContentHeader';
import Modal from 'components/core/Modal';
import Paginate from 'components/core/Paginate';
import Loader from 'components/core/Loader';
import { Creators as mediumActions } from 'store/ducks/mediumReducer';
import {
  Table,
  Th,
  TableBody,
  Tr,
  Td,
  TableHead,
} from 'components/core/Table/styles';
import moment from 'moment-timezone';
import {
  ContentWrapper,
  TableButtonsWrapper,
} from 'components/core/Wrappers/styles';
import editIcon from 'assets/icons/edit.svg';
import trashIcon from 'assets/icons/trash.svg';
import AddEditSlugForm from 'components/presentation/modal/AddEditSlugForm';
import FilterBar from 'components/presentation/FilterBar';
import ModalStatus from 'components/presentation/modal/ModalStatus';
import ModalConfirmation from 'components/presentation/modal/ModalConfirmation';
import sortData from 'utils/sortFilter/sort';
import { SortButton } from 'components/core/Button/ButtonStyle';

moment.locale('pt-br');

const headers = [
  <FormattedMessage id="name" />,
  <FormattedMessage id="slug" />,
  <FormattedMessage id="creationDate" />,
  <FormattedMessage id="lastEditDate" />,
  '',
];

const MediumList = () => {
  const dispatch = useDispatch();
  const { mediumData, isLoading, pagination, status } = useSelector(
    ({ mediumReducer }) => mediumReducer,
  );
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showFilter, setShowFilter] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ascDesc, setAscDesc] = useState(false);
  const [editData, setEditData] = useState();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [mediumID, setMediumID] = useState('');
  const [responseStatus, setResponseStatus] = useState('success');
  const [requestStatusType, setRequestStatusType] = useState();

  const handlePagination = (pageNumber) => {
    if (pageNumber === currentPage) {
      return;
    }
    dispatch(mediumActions.mediumRequest(pageNumber));
    setCurrentPage(pageNumber);
  };

  const handlePaginationPrev = () => {
    if (currentPage > 1) {
      dispatch(mediumActions.mediumRequest(currentPage - 1));
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePaginationNext = () => {
    if (currentPage < pagination.totalPages) {
      dispatch(mediumActions.mediumRequest(currentPage + 1));
      setCurrentPage(currentPage + 1);
    }
  };

  const handleEditMedium = (params) => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setEditData(params);
    setErrorMessage('');
  };

  const handleModalDelete = (id) => {
    requestStatusType && setRequestStatusType('');
    setShowDeleteModal(true);
    setMediumID(id);
  };

  const handleAddMedium = () => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setErrorMessage('');
    setEditData(undefined);
  };

  const statusMethod = useCallback(() => {
    switch (requestStatusType) {
      case 'medium-already-registered':
        setErrorMessage(<FormattedMessage id="nameOrSlugRegistered" />);
        setRequestStatusType('');
        break;

      case 'is-not-allowed':
        setShowAddEditModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(<FormattedMessage id="adminPermission" />);
        setShowStatusModal(true);
        break;

      case 'medium-associated-with-a-qrcode':
        setShowAddEditModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(
          <FormattedMessage id="mediumAssociatedWithQrcode" />,
        );
        setShowStatusModal(true);
        break;

      case 'delete-success':
        setResponseStatus('success');
        setShowDeleteModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="mediumDeleteSuccess" />);
        break;

      case 'create-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="mediumAddSuccess" />);
        break;

      case 'update-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="mediumEditSuccess" />);
        break;

      default:
        break;
    }
  }, [requestStatusType]);

  // Modal Contents

  const addMediumModalForm = (
    <AddEditSlugForm
      name="Medium"
      errorMessage={errorMessage}
      data={editData}
      dispatchAdd={mediumActions.mediumCreateRequest}
      dispatchEdit={mediumActions.mediumUpdateRequest}
    />
  );

  useEffect(() => {
    dispatch(mediumActions.mediumRequest(1));
    if (status) {
      setRequestStatusType(status);
    }
    statusMethod();
  }, [dispatch, status]);

  return (
    <>
      <Loader loading={isLoading} />
      <Modal
        title={
          editData ? (
            <FormattedMessage id="editMedium" />
          ) : (
            <FormattedMessage id="addMedium" />
          )
        }
        setIsOpen={setShowAddEditModal}
        isOpen={showAddEditModal}
        content={addMediumModalForm}
        loading={isLoading}
      />
      <ModalStatus
        status={responseStatus}
        setIsOpen={setShowStatusModal}
        isOpen={showStatusModal}
        text={confirmationMessage}
      />
      <ModalConfirmation
        title={<FormattedMessage id="areYouSure" />}
        buttonName={<FormattedMessage id="delete" />}
        text={<FormattedMessage id="mediumDeleteConfirmMessage" />}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        loading={isLoading}
        confirmOnClick={() => {
          dispatch(mediumActions.mediumDeleteRequest(mediumID));
          setConfirmationMessage(<FormattedMessage id="mediumDeleteSuccess" />);
        }}
      />
      <Header
        title="Medium"
        addButtonName={<FormattedMessage id="add" />}
        filterBtn
        onClickAdd={handleAddMedium}
        onClickFilter={() => setShowFilter(1)}
      />

      <ContentWrapper>
        <FilterBar
          display={showFilter}
          onClickClose={() => setShowFilter(0)}
          dateDispatch={mediumActions.mediumFilterByDateRequest}
          mediumDispatch={mediumActions.mediumFilterByNameRequest}
          allListDispatch={mediumActions.mediumRequest(1)}
        />
        <Table>
          <TableHead>
            <Tr>
              {headers.map((header, index) => (
                <Th key={index}>
                  {header}
                  {header && (
                    <SortButton
                      onClick={() =>
                        sortData(mediumData, header, ascDesc, setAscDesc)
                      }
                    />
                  )}
                </Th>
              ))}
            </Tr>
          </TableHead>
          <TableBody>
            {mediumData &&
              mediumData.map((medium) => (
                <Tr key={medium.id}>
                  <Td>{medium.name}</Td>
                  <Td>{medium.slug}</Td>
                  <Td>{moment(medium.createdAt).utc().format('DD/MM/yyyy')}</Td>
                  <Td>{moment(medium.updatedAt).utc().format('DD/MM/yyyy')}</Td>
                  <Td>
                    <TableButtonsWrapper>
                      <Button
                        width="32px"
                        height="32px"
                        icon={editIcon}
                        onClick={() => handleEditMedium(medium)}
                      />
                      <Button
                        width="32px"
                        height="32px"
                        icon={trashIcon}
                        onClick={() => handleModalDelete(medium.id)}
                      />
                    </TableButtonsWrapper>
                  </Td>
                </Tr>
              ))}
          </TableBody>
        </Table>
        {pagination && (
          <Paginate
            totalPages={pagination.totalPages}
            currentPage={currentPage}
            onClick={(e) => handlePagination(e)}
            onClickPrev={handlePaginationPrev}
            onClickNext={handlePaginationNext}
          />
        )}
      </ContentWrapper>
    </>
  );
};

export default MediumList;
