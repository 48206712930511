import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import FormInput from 'components/core/FormInput';
import { FormattedMessage } from 'react-intl';
import Button from 'components/core/Button';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as QrCodesActions } from 'store/ducks/qrCodesReducer';
import Swal from 'sweetalert2';

const QRCode = require('qrcode.react');

const QRCodeForm = () => {
  const dispatch = useDispatch();
  const initialValues = {
    link: '',
    name: '',
    redirect: '',
  };
  const validationSchema = yup.object().shape({
    redirect: yup
      .string()
      .required(<FormattedMessage id="validations.redirect" />),
    name: yup.string().required(<FormattedMessage id="validations.name" />),
    link: yup
      .string()
      .required(<FormattedMessage id="validations.qrcode" />)
      .matches(
        /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
        'Valor digitado não atende ao padrão requerido, exemplo: tintas-suvinil-exemplo',
      ),
  });

  const [showQrCode, setShowQrCode] = useState(false);
  const qrCodesReducer = useSelector((state) => state.qrCodesReducer);

  const renderAlert = () => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Um error ocorreu ao criar o redirecionamento',
    });
  };

  useEffect(() => {
    if (qrCodesReducer.qrcode && !qrCodesReducer.error) {
      setShowQrCode(!showQrCode);
    } else if (qrCodesReducer.error) {
      renderAlert();
    }
  }, [qrCodesReducer]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(QrCodesActions.qrCodesCreateRequest(values));
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <>
            <Row>
              <Col md="6">
                <FormInput
                  type="email"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isError={errors.name && touched.name}
                  error={errors.name}
                >
                  <FormattedMessage id="name" />
                </FormInput>
              </Col>
              <Col md="6">
                {showQrCode && (
                  <QRCode
                    renderAs="svg"
                    level="H"
                    size="256"
                    value={qrCodesReducer.qrcode.link}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormInput
                  type="email"
                  id="redirect"
                  name="redirect"
                  value={values.redirect}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isError={errors.redirect && touched.redirect}
                  error={errors.redirect}
                >
                  <FormattedMessage id="redirect" />
                </FormInput>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormInput
                  id="link"
                  name="link"
                  value={`https://qrcodes.suvinil.com.br/${values.link}`}
                  error={errors.link}
                >
                  <FormattedMessage id="link" />
                </FormInput>
              </Col>
              <Col md="6">
                <FormInput
                  id="link"
                  name="link"
                  value={values.link}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isError={errors.link && touched.link}
                  error={errors.link}
                >
                  <FormattedMessage id="Slug" />
                </FormInput>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Button onClick={handleSubmit} width="250">
                  <FormattedMessage id="create" />
                </Button>
              </Col>
            </Row>
          </>
        );
      }}
    </Formik>
  );
};
export default QRCodeForm;
