import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import store from './store/store';
import Routes from './utils/routes';
import GlobalStyles from './theme/global';
import theme from './theme';
import flattenMessages from './helpers/flattenMessages';
import locale from './locale';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const localeLg = navigator.language;
const localeStrings = locale[localeLg] ? locale[localeLg] : locale['pt-BR'];

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <GlobalStyles />
      <IntlProvider locale={localeLg} messages={flattenMessages(localeStrings)}>
        <Routes />
      </IntlProvider>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));
