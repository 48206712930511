import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import urls from 'config/constants/urls';
import { sidebar as Tracker } from 'utils/dataLayer';
import {
  Container,
  Content,
  Menu,
  BorderBottom,
  LogoContainer,
} from './SidebarStyle';
import logo from '../../../assets/img/logo.png';

const Sidebar = ({ active }) => {
  const location = useLocation();

  const [isActive, setIsActive] = useState('');

  const handleSidebarMenu = (name) => {
    name === 'QR Codes' ? Tracker.qrCodeListPage() : Tracker.exit();
    name === 'Sair' && localStorage.clear();
  };

  useEffect(() => {
    const path = location.pathname;
    const urlPath = Object.keys(urls.ROUTES).find(
      (url) => urls.ROUTES[url].path === path,
    );
    setIsActive(urls.ROUTES[urlPath].name);
  }, [isActive, location]);

  return (
    <Container Col="3">
      <LogoContainer>
        <img src={logo} alt="" />
      </LogoContainer>
      <Content>
        <Row>
          <Col>
            {Object.keys(urls.ROUTES).map((route) => {
              const { show, path, name, icon } = urls.ROUTES[route];
              if (show) {
                return (
                  <Link
                    key={path}
                    to={path}
                    onClick={() => handleSidebarMenu(name)}
                  >
                    <Menu
                      active={active === 'crawlers'}
                      className={isActive === name ? 'active' : ''}
                      onClick={() => setIsActive(name)}
                    >
                      <img src={icon} alt="" />
                      {name}
                    </Menu>
                    <BorderBottom
                      style={{ display: name === 'Sair' ? 'none' : 'block' }}
                    />
                  </Link>
                );
              }
              return null;
            })}
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default Sidebar;
