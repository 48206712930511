import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import BackgroundHalfContainer from 'components/core/BackgroundHalfContainer';
import { Container } from './styles';

const EmailSentConfirmation = () => {
  const { email } = useSelector((state) => state.forgotPasswordReducer);

  return (
    <Container>
      <BackgroundHalfContainer title={<FormattedMessage id="emailSent" />}>
        <p>
          Verifique a caixa de entrada de <span>{email}</span> para ver as
          instruções para redefinir a senha.
        </p>
      </BackgroundHalfContainer>
    </Container>
  );
};

export default EmailSentConfirmation;
