import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as usersActions } from 'store/ducks/usersReducer';
import { FormattedMessage } from 'react-intl';
import Button from 'components/core/Button';
import Modal from 'components/core/Modal';
import ModalStatus from 'components/presentation/modal/ModalStatus';
import Header from 'components/core/ContentHeader';
import Paginate from 'components/core/Paginate';
import Loader from 'components/core/Loader';
import FilterBar from 'components/presentation/FilterBar';
import {
  Table,
  Th,
  TableBody,
  Tr,
  Td,
  TableHead,
} from 'components/core/Table/styles';
import moment from 'moment-timezone';
import AddEditUserForm from 'components/presentation/modal/AddEditUserForm';
import ModalConfirmation from 'components/presentation/modal/ModalConfirmation';
import {
  ContentWrapper,
  TableButtonsWrapper,
} from 'components/core/Wrappers/styles';
import editIcon from 'assets/icons/edit.svg';
import trashIcon from 'assets/icons/trash.svg';
import sortData from 'utils/sortFilter/sort';
import { SortButton } from 'components/core/Button/ButtonStyle';

moment.locale('pt-br');

const headers = [
  <FormattedMessage id="name" />,
  <FormattedMessage id="email" />,
  <FormattedMessage id="role" />,
  '',
];

const UsersList = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [ascDesc, setAscDesc] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [userID, setUserID] = useState('');
  const [editData, setEditData] = useState();
  const [showFilter, setShowFilter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, pagination, status } = useSelector(
    ({ usersReducer }) => usersReducer,
  );
  const dispatch = useDispatch();
  const [responseStatus, setResponseStatus] = useState('success');
  const [requestStatusType, setRequestStatusType] = useState();

  const handleModalCreate = useCallback(() => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setEditData(undefined);
    setEmailErrorMessage('');
  }, []);

  const handleModalEdit = useCallback((params) => {
    requestStatusType && setRequestStatusType('');
    setShowAddEditModal(true);
    setEditData(params);
    setEmailErrorMessage('');
  }, []);

  const handleModalDelete = useCallback((id) => {
    requestStatusType && setRequestStatusType('');
    setShowDeleteModal(true);
    setUserID(id);
  }, []);

  const handlePagination = (pageNumber) => {
    if (pageNumber) {
      dispatch(usersActions.usersListRequest(pageNumber));
      setCurrentPage(pageNumber);
    }
  };

  const handlePaginationPrev = () => {
    if (currentPage > 1) {
      dispatch(usersActions.usersListRequest(currentPage - 1));
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePaginationNext = () => {
    if (currentPage < pagination.totalPages) {
      dispatch(usersActions.usersListRequest(currentPage + 1));
      setCurrentPage(currentPage + 1);
    }
  };

  const statusMethod = useCallback(() => {
    switch (requestStatusType) {
      case 'Email already registered':
        setEmailErrorMessage(<FormattedMessage id="emailAlreadyRegistered" />);
        setRequestStatusType('');
        break;

      case 'is-not-allowed':
        setShowAddEditModal(false);
        setShowDeleteModal(false);
        setResponseStatus('error');
        setConfirmationMessage(<FormattedMessage id="adminPermission" />);
        setShowStatusModal(true);
        break;

      case 'delete-success':
        setResponseStatus('success');
        setShowDeleteModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="userDeleteSuccess" />);
        break;

      case 'create-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="userAddSuccess" />);
        break;

      case 'update-success':
        setResponseStatus('success');
        setShowAddEditModal(false);
        setShowStatusModal(true);
        setConfirmationMessage(<FormattedMessage id="userEditSuccess" />);
        break;

      default:
        break;
    }
  }, [requestStatusType]);

  useEffect(() => {
    dispatch(usersActions.usersListRequest(1));
    if (status) {
      setRequestStatusType(status);
    }
    statusMethod();
  }, [dispatch, status]);

  const addEditUserModalForm = (
    <AddEditUserForm emailErrorMessage={emailErrorMessage} data={editData} />
  );

  return (
    <>
      <Loader loading={isLoading} />

      <Modal
        title={
          editData ? (
            <FormattedMessage id="editUser" />
          ) : (
            <FormattedMessage id="addNewUser" />
          )
        }
        setIsOpen={setShowAddEditModal}
        isOpen={showAddEditModal}
        content={addEditUserModalForm}
        loading={isLoading}
      />
      <ModalStatus
        status={responseStatus}
        setIsOpen={setShowStatusModal}
        isOpen={showStatusModal}
        text={confirmationMessage}
      />
      <ModalConfirmation
        title={<FormattedMessage id="areYouSure" />}
        buttonName={<FormattedMessage id="delete" />}
        text={<FormattedMessage id="userDeleteConfirmMessage" />}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        loading={isLoading}
        confirmOnClick={() => {
          dispatch(usersActions.usersDeleteRequest(userID));
          setConfirmationMessage(<FormattedMessage id="userDeleteSuccess" />);
        }}
      />

      <Header
        title={<FormattedMessage id="users" />}
        addButtonName={<FormattedMessage id="addUser" />}
        filterBtn
        onClickAdd={handleModalCreate}
        onClickFilter={() => setShowFilter(1)}
      />
      <ContentWrapper>
        <FilterBar
          user
          display={showFilter}
          onClickClose={() => setShowFilter(0)}
          allListDispatch={usersActions.usersListRequest(1)}
        />
        <Table>
          <TableHead>
            <Tr>
              {headers.map((header, index) => (
                <Th key={index}>
                  {header}
                  {header && (
                    <SortButton
                      onClick={() =>
                        sortData(data, header, ascDesc, setAscDesc)
                      }
                    />
                  )}
                </Th>
              ))}
            </Tr>
          </TableHead>
          <TableBody>
            {data &&
              data.map((user) => (
                <Tr key={user.id}>
                  <Td>{user.name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.role.type}</Td>
                  <Td>
                    <TableButtonsWrapper>
                      <Button
                        width="32px"
                        height="32px"
                        icon={editIcon}
                        onClick={() => handleModalEdit(user)}
                      />
                      <Button
                        width="32px"
                        height="32px"
                        icon={trashIcon}
                        onClick={() => handleModalDelete(user.id)}
                      />
                    </TableButtonsWrapper>
                  </Td>
                </Tr>
              ))}
          </TableBody>
        </Table>
        <Paginate
          totalPages={pagination.totalPages}
          currentPage={currentPage}
          onClick={(e) => handlePagination(e)}
          onClickPrev={handlePaginationPrev}
          onClickNext={handlePaginationNext}
        />
      </ContentWrapper>
    </>
  );
};

export default UsersList;
