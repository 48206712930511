const sortData = (data, type, ascDesc, setAscDesc) => {
  if (!ascDesc) {
    switch (type) {
      case 'Nome':
        data.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
          return 0;
        });
        break;

      case 'Data de criação':
        data.sort((a, b) => {
          if (a.createdAt > b.createdAt) return 1;
          if (b.createdAt > a.createdAt) return -1;
          return 0;
        });
        break;

      case 'Data da última edição':
        data.sort((a, b) => {
          if (a.updatedAt > b.updatedAt) return 1;
          if (b.updatedAt > a.updatedAt) return -1;
          return 0;
        });
        break;

      case 'Campaign':
        data.sort((a, b) => {
          if (a.campaign.name.toLowerCase() > b.campaign.name.toLowerCase())
            return 1;
          if (b.campaign.name.toLowerCase() > a.campaign.name.toLowerCase())
            return -1;
          return 0;
        });
        break;

      case 'Medium':
        data.sort((a, b) => {
          if (a.medium.name.toLowerCase() > b.medium.name.toLowerCase())
            return 1;
          if (b.medium.name.toLowerCase() > a.medium.name.toLowerCase())
            return -1;
          return 0;
        });
        break;

      case 'Slug':
        data.sort((a, b) => {
          if (a.slug > b.slug) return 1;
          if (b.slug > a.slug) return -1;
          return 0;
        });
        break;

      case 'E-mail':
        data.sort((a, b) => {
          if (a.email > b.email) return 1;
          if (b.email > a.email) return -1;
          return 0;
        });
        break;

      case 'Permissão':
        data.sort((a, b) => {
          if (a.role.type > b.role.type) return 1;
          if (b.role.type > a.role.type) return -1;
          return 0;
        });
        break;

      default:
        break;
    }

    setAscDesc(true);
  } else {
    data.reverse();
    setAscDesc(false);
  }
};

export default sortData;
