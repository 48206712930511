import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Button from 'components/core/Button';
import FormInput from 'components/core/FormInput';
import Select from 'components/core/Select';
import roles from 'utils/data/roles.json';
import { Creators as usersActions } from 'store/ducks/usersReducer';
import { Container } from './styles';

const AddEditUserForm = ({ emailErrorMessage, data }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const initialValues = {
    name: data.name ? data.name : '',
    email: data.email ? data.email : '',
    password: '',
    role: data.role.id ? data.role.id : '',
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="unfilledName" />),
    email: yup.string().required(<FormattedMessage id="unfilledEmail" />),
    password: yup
      .string()
      .required(<FormattedMessage id="unfilledPassword" />)
      .matches(
        /^(?=.{6,16}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        <FormattedMessage id="userPasswordCreationMessage" />,
      ),
    role: yup.string().required(<FormattedMessage id="unfilledRole" />),
  });

  const editValidationSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="unfilledName" />),
    email: yup.string().required(<FormattedMessage id="unfilledEmail" />),
    role: yup.string().required(<FormattedMessage id="unfilledRole" />),
  });

  const handleSubmitForm = (values) => {
    if (data.name) {
      dispatch(usersActions.usersUpdateRequest(values, data.id));
    } else {
      dispatch(usersActions.usersCreateRequest(values));
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={data.name ? editValidationSchema : validationSchema}
        onSubmit={(values) => handleSubmitForm(values)}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <Form>
            <Row>
              <Col md="12">
                <FormInput
                  type="name"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'enterFullname' })}
                  isError={errors.name && touched.name}
                  error={errors.name}
                >
                  <FormattedMessage id="nameRequired" />
                </FormInput>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormInput
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="exemplo@email.com.br"
                  isError={(errors.email && touched.email) || emailErrorMessage}
                  error={errors.email || emailErrorMessage}
                >
                  <FormattedMessage id="emailRequired" />
                </FormInput>
              </Col>
            </Row>
            {!data.name && (
              <Row>
                <Col md="12">
                  <FormInput
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({ id: 'enterPassword' })}
                    isError={errors.password && touched.password}
                    error={errors.password}
                  >
                    <FormattedMessage id="passwordRequired" />
                  </FormInput>
                </Col>
              </Row>
            )}
            <Row>
              <Col md="12">
                <Select
                  type="role"
                  id="role"
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                  options={roles.roles}
                  isError={errors.role && touched.role}
                  error={errors.role}
                >
                  Role
                </Select>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Button
                type="submit"
                btnType="primary"
                onClick={handleSubmit}
                width="250px"
                height="44px"
                borderRadius="8px"
              >
                {data.name ? (
                  <FormattedMessage id="edit" />
                ) : (
                  <FormattedMessage id="add" />
                )}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

AddEditUserForm.propTypes = {
  emailErrorMessage: PropTypes.string,
  data: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    passwordResetToken: PropTypes.string,
    role: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

AddEditUserForm.defaultProps = {
  emailErrorMessage: '',
  data: {
    email: '',
    id: '',
    name: '',
    passwordResetToken: '',
    role: {
      id: '',
    },
  },
};

export default AddEditUserForm;
