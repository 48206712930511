import styled, { css } from 'styled-components';
import theme from 'theme/';
import arrowDownIcon from '../../../assets/icons/arrow-down.svg';
import arrowDownPrimaryIcon from '../../../assets/icons/arrow-down-primary.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 15px;

  .form-group {
    margin: 0;

    select {
      position: relative;
      margin: 0;
      outline: 0;
      line-height: 1.21em;
      padding: 0.67em 3em 0.67em 1em;
      font-size: 1em;
      background: ${theme.colors.defaultInput} url(${arrowDownIcon}) no-repeat
        right 16px center/20px 22px;
      border: none;
      border-radius: 5px;
      box-shadow: inset 0 0 0 0 transparent;
      transition: color 0.1s ease, border-color 0.1s ease;
      height: 48px;
      width: 100%;
      appearance: none;

      ${({ isError }) => isError && `border: 1px solid ${theme.colors.error}`}

      ${({ secondary }) =>
        secondary &&
        css`
          font-size: 12px;
          font-family: 'Montserrat';
          height: 38px;
          border: 1px solid #ecedf1;
          border-radius: 8px;
          background: transparent url(${arrowDownPrimaryIcon}) no-repeat right
            12px center/18px 21px;
        `};
    }
  }
`;

export const Label = styled.label`
  color: ${theme.colors.darkGray};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;

  ${({ isError }) => isError && `color: ${theme.colors.error}`}

  ${({ secondary }) =>
    secondary &&
    css`
      font-family: 'Montserrat';
    `};
`;
