import styled from 'styled-components';

export const TableButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    img {
      margin: 0;
    }

    :not(:first-child) {
      margin-left: 12px;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding: 32px 40px 40px 67px;
`;
