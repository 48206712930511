import React from 'react';

import Content from './ButtonStyle';

const Button = ({
  type,
  onClick,
  disabled,
  btnType,
  children,
  width,
  borderRadius,
  height,
  style,
  className,
  uppercase,
  fontWeight,
  fontFamily,
  icon,
}) => (
  <Content
    type={type}
    btnType={btnType}
    onClick={onClick}
    disabled={disabled}
    width={width}
    borderRadius={borderRadius}
    height={height}
    style={style}
    className={className}
    uppercase={uppercase}
    fontWeight={fontWeight}
    fontFamily={fontFamily}
  >
    {icon && <img src={icon} alt="" />}
    {children}
  </Content>
);

export default Button;
