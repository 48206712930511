import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/core/Button';
import FormInput from 'components/core/FormInput';
import { Container } from './styles';

const AddEditSlugForm = ({
  name,
  data,
  errorMessage,
  dispatchEdit,
  dispatchAdd,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const initialValues = {
    name: data.name ? data.name : '',
    slug: data.slug ? data.slug : '',
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(<FormattedMessage id="fieldRequired" />),
    slug: yup
      .string()
      .required(<FormattedMessage id="fieldRequired" />)
      .test({
        message: intl.formatMessage({ id: 'invalidSlug' }),
        test(value) {
          return value && /^[a-zA-Z0-9-]*$/.test(value);
        },
      }),
  });

  const handleSubmitForm = (values) => {
    if (data.name) {
      dispatch(dispatchEdit(values, data.id));
    } else {
      dispatch(dispatchAdd(values));
    }
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmitForm(values)}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <Form>
            <Row>
              <Col md="12">
                <FormInput
                  type="name"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder={intl.formatMessage({ id: 'enterFullname' })}
                  isError={(errors.name && touched.name) || errorMessage}
                  error={errors.name || errorMessage}
                >
                  {name}
                </FormInput>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormInput
                  type="slug"
                  id="slug"
                  name="slug"
                  value={values.slug}
                  onChange={handleChange}
                  placeholder="suvinil-exemplo-cores"
                  isError={(errors.slug && touched.slug) || errorMessage}
                  error={errors.slug || errorMessage}
                >
                  Slug
                </FormInput>
              </Col>
            </Row>
            <Row className="justify-content-center mt-4">
              <Button
                type="submit"
                btnType="primary"
                onClick={handleSubmit}
                width="250px"
                height="44px"
                borderRadius="8px"
              >
                {data.name ? (
                  <FormattedMessage id="edit" />
                ) : (
                  <FormattedMessage id="add" />
                )}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

AddEditSlugForm.propTypes = {
  name: PropTypes.string,
  errorMessage: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  dispatchAdd: PropTypes.func,
  dispatchEdit: PropTypes.func,
};

AddEditSlugForm.defaultProps = {
  name: '',
  errorMessage: '',
  data: {
    name: '',
    slug: '',
  },
  dispatchAdd: () => {},
  dispatchEdit: () => {},
};

export default AddEditSlugForm;
