import userIcon from 'assets/icons/user.svg';
import logoutIcon from 'assets/icons/logout.svg';
import loudspeakerIcon from 'assets/icons/loudspeaker.svg';
import paintBucketIcon from 'assets/icons/paint-bucket.svg';
import qrCodeIcon from 'assets/icons/qr-code.svg';

const urls = {
  ROUTES: {
    APP: { path: '/config/admin', name: '' },
    REDIRECT: { path: '/:slug', name: 'QR Code Suvinil' },
    USERS: {
      path: '/config/admin/users',
      name: 'Usuários',
      show: true,
      icon: userIcon,
    },
    QR_CODES: {
      path: '/config/admin/qrcodes',
      name: 'QR Codes',
      show: true,
      icon: qrCodeIcon,
    },
    MEDIUM: {
      path: '/config/admin/medium',
      name: 'Medium',
      show: true,
      icon: paintBucketIcon,
    },
    CAMPAIGN: {
      path: '/config/admin/campaign',
      name: 'Campaign',
      show: true,
      icon: loudspeakerIcon,
    },
    LOGOUT: {
      path: '/config/admin/',
      name: 'Sair',
      show: true,
      icon: logoutIcon,
    },
    FORGOT_PASSWORD_EMAIL_ENTRY: {
      path: '/config/admin/reset-password',
      name: 'Recuperar senha',
    },
    FORGOT_PASSWORD_EMAIL_CONFIRMATION: { path: '/config/admin/email-sent' },
    FORGOT_PASSWORD_RESET_CONFIRMATION: { path: '/config/admin/reset-success' },
    FORGOT_PASSWORD_NEW_PASSWORD: { path: '/config/admin/new-password' },
  },
  LINKS: {
    APP: '/config/admin',
    QR_CODES: '/config/admin/qrcodes',
    NEW_QR_CODES: '/config/admin/qrcodes/new',
  },
};

export default urls;
