import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'hooks/useOutsideClick';
import closeIcon from 'assets/icons/close.svg';
import Button from 'components/core/Button';
import Loader from 'components/core/Loader';

import {
  Container,
  ModalHeader,
  ModalContent,
  ModalBody,
  Title,
} from './styles';

const Modal = ({
  setIsOpen,
  isOpen,
  title,
  content,
  titleRight,
  titleSize,
  loading,
}) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  return (
    isOpen && (
      <Container>
        <ModalContent ref={wrapperRef}>
          <Loader loading={loading} />
          <ModalHeader titleRight={titleRight} titleSize={titleSize}>
            <Title>{title}</Title>
            <Button
              onClick={() => setIsOpen(false)}
              icon={closeIcon}
              btnType="light"
              width="40px"
              height="40px"
            />
          </ModalHeader>
          <ModalBody>{content}</ModalBody>
        </ModalContent>
      </Container>
    )
  );
};

Modal.propTypes = {
  title: PropTypes.node,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  titleSize: PropTypes.string,
  titleRight: PropTypes.bool,
  loading: PropTypes.number,
};

Modal.defaultProps = {
  title: null,
  setIsOpen: () => {},
  isOpen: false,
  titleSize: '',
  titleRight: false,
  loading: 0,
};

export default Modal;
