/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import PasswordConfirmation from 'containers/ForgotPassword/PasswordConfirmation';
import RedirectComponent from 'containers/Redirect';
import Home from 'containers/Home';
import Auth from 'containers/Auth';
import EmailEntry from 'containers/ForgotPassword/EmailEntry';
import EmailConfirmation from 'containers/ForgotPassword/EmailConfirmation';
import urls from 'config/constants/urls';
import NewPassword from 'containers/ForgotPassword/NewPassword';
import isAuthenticated from './isAuthenticated';

export const history = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: urls.ROUTES.APP.path,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const {
  APP,
  USERS,
  MEDIUM,
  CAMPAIGN,
  QR_CODES,
  REDIRECT,
  FORGOT_PASSWORD_EMAIL_ENTRY,
  FORGOT_PASSWORD_EMAIL_CONFIRMATION,
  FORGOT_PASSWORD_RESET_CONFIRMATION,
  FORGOT_PASSWORD_NEW_PASSWORD,
} = urls.ROUTES;

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact {...APP} component={Auth} />
      <Route exact {...FORGOT_PASSWORD_EMAIL_ENTRY} component={EmailEntry} />
      <Route
        exact
        {...FORGOT_PASSWORD_EMAIL_CONFIRMATION}
        component={EmailConfirmation}
      />
      <Route
        exact
        {...FORGOT_PASSWORD_RESET_CONFIRMATION}
        component={PasswordConfirmation}
      />
      <PrivateRoute {...QR_CODES} component={Home} />
      <PrivateRoute {...USERS} component={Home} />
      <PrivateRoute {...MEDIUM} component={Home} />
      <PrivateRoute {...CAMPAIGN} component={Home} />
      <Route exact {...REDIRECT} component={RedirectComponent} />
      <Route exact {...FORGOT_PASSWORD_NEW_PASSWORD} component={NewPassword} />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
