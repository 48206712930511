import axios from 'axios';
import { basePath } from 'config/constants/endPoints';
import { POST, PUT, DELETE } from 'config/constants/verbs';

const API = axios.create({
  baseURL: basePath,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `basic ${localStorage.getItem('Authorization')}`,
  },
});

const defineEndpoint = (endpoint, id, query) => {
  if (typeof endpoint === 'function') {
    return endpoint(id);
  }
  if (query) {
    return `${endpoint}?${query}`;
  }
  if (id) {
    return `${endpoint}/${id}`;
  }
  return endpoint;
};

API.requestServer = ({ verb, endpoint, data, id, query }) => {
  const token = localStorage.getItem('token');
  Object.assign(API.defaults, {
    headers: { Authorization: `Bearer ${token}` },
  });

  switch (verb) {
    case POST:
      return API.post(defineEndpoint(endpoint, id), data)
        .then((res) => res)
        .catch((error) => {
          throw error;
        });
    case PUT:
      return API.put(defineEndpoint(endpoint, id), data)
        .then((res) => res)
        .catch((error) => {
          throw error;
        });
    case DELETE:
      return API.delete(defineEndpoint(endpoint, id))
        .then((res) => res)
        .catch((error) => {
          throw error;
        });
    default:
      // GET
      return API.get(defineEndpoint(endpoint, id, query))
        .then((res) => res)
        .catch((error) => {
          throw error;
        });
  }
};

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
export default API;
