import styled from 'styled-components';
import theme from 'theme/';

const { defaultInput, secondary, darkGray } = theme.colors;

export const Container = styled.div`
  width: 796px;
  padding: 10px 24px;
`;

export const EmptyQrcode = styled.div`
  width: 220px;
  height: 220px;
  background: ${defaultInput};
  border: 4px dashed ${secondary};
`;

export const QrcodeImage = styled.img`
  width: 220px;
  height: 220px;
`;

export const SuccessText = styled.p`
  margin: 20px 0 0;
  font-size: 18px;
  color: green;
`;

export const LinkLabel = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: bold;
`;

export const LinksUrl = styled.p`
  font-size: 14px;
  color: ${darkGray};
  overflow-wrap: break-word;
`;
