import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Creators as QrCodesActions } from 'store/ducks/qrCodesReducer';
import loading from 'assets/gifs/loading.gif';
import { Container } from './styles';

const Redirect = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const qrCodesReducer = useSelector((state) => state.qrCodesReducer);

  const addHttpIfNotPresent = (qrCodesReducerParam) => {
    const { urlRedirectFinal } = qrCodesReducerParam.qrcode;
    const hasHttp = urlRedirectFinal.startsWith('http');
    const urlWithHTTPS = hasHttp
      ? urlRedirectFinal
      : `https://${urlRedirectFinal}`;
    return urlWithHTTPS;
  };

  useEffect(() => {
    dispatch(QrCodesActions.qrCodeRedirectRequest(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    if (qrCodesReducer.qrcode && !qrCodesReducer.error) {
      const urlWithHTTPS = addHttpIfNotPresent(qrCodesReducer);
      window.location.href = urlWithHTTPS;
    } else if (qrCodesReducer.error) {
      window.location.href = 'https://www.suvinil.com.br/';
    }
  }, [qrCodesReducer]);

  return (
    <Container>
      <img src={loading} alt="" />
      <p>Aguarde, você está sendo redirecionado</p>
    </Container>
  );
};

export default Redirect;
