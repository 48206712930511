import styled from 'styled-components';
import theme from 'theme';

export const Container = styled.p`
  color: ${theme.colors.error};
  font-size: 14px;
  padding-top: 5px;
  margin: 0;
  text-align: end;
  line-height: normal;
`;
