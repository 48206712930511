import React from 'react';
import PropTypes from 'prop-types';
import { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import { Container, InputStyle, Label } from './styles';

registerLocale('pt', pt);

const DateInput = ({
  label,
  onChange,
  selected,
  startDate,
  endDate,
  selectsStart,
  selectsEnd,
}) => (
  <Container>
    <Label>{label}</Label>
    <InputStyle
      locale="pt"
      selected={selected}
      onChange={onChange}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd/MM/yyyy"
    />
  </Container>
);

DateInput.propTypes = {
  label: PropTypes.node,
  selected: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  selectsStart: PropTypes.bool,
  selectsEnd: PropTypes.bool,
};

DateInput.defaultProps = {
  label: null,
  selected: null,
  startDate: new Date(),
  endDate: new Date(),
  onChange: () => {},
  selectsStart: false,
  selectsEnd: false,
};

export default DateInput;
