/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/core/Button';
import FormInput from 'components/core/FormInput';
import downloadIcon from 'assets/icons/download-primary.svg';
import downloadSVG from 'utils/downloadSVG';
import { Container, DownloadButton } from './styles';

const QrCodeDownload = ({ onSubmit, data }) => {
  const { urlRedirectFinal, link, name } = data;
  const initialValues = {
    link: urlRedirectFinal,
  };
  const intl = useIntl();

  const copyLink = (redirectLink) => {
    navigator.clipboard.writeText(redirectLink);
    toast.success(intl.formatMessage({ id: 'linkCopied' }));
  };

  return (
    <Container>
      <QRCode id="qrcode" value={link} size={220} />
      <DownloadButton
        onClick={() => downloadSVG(name, 'qrcode')}
        uppercase
        height="20px"
      >
        <FormattedMessage id="downloadQrcode" />
        <img src={downloadIcon} alt="" />
      </DownloadButton>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ values }) => (
          <Row className="w-100 mt-4">
            <Col md="9" className="pr-0">
              <FormInput
                type="link"
                id="link"
                name="link"
                value={values.link}
                readOnly
              >
                <FormattedMessage id="link" />
              </FormInput>
            </Col>
            <Col md="3" className="pl-2 pb-3 d-flex align-items-end">
              <Button
                type="button"
                btnType="primary"
                width="116px"
                height="47px"
                onClick={() => copyLink(values.link)}
              >
                <FormattedMessage id="copy" />
              </Button>
            </Col>
          </Row>
        )}
      </Formik>
    </Container>
  );
};

QrCodeDownload.propTypes = {
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    urlRedirectFinal: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
  }),
};

QrCodeDownload.defaultProps = {
  onSubmit: () => {},
  data: {},
};

export default QrCodeDownload;
