import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  usersListRequest: ['page'],
  usersListSuccess: ['users'],
  usersListFail: ['error'],

  usersCreateRequest: ['payload'],
  usersCreateSuccess: [],
  usersCreateFail: ['error'],

  usersUpdateRequest: ['payload', 'id'],
  usersUpdateSuccess: [],
  usersUpdateFail: ['error'],

  usersDeleteRequest: ['id'],
  usersDeleteSuccess: [],
  usersDeleteFail: ['error'],

  usersFilterBySearchRequest: ['value'],
  usersFilterBySearchSuccess: [],
  usersFilterBySearchFail: ['error'],

  usersFilterByRoleRequest: ['role'],
  usersFilterByRoleSuccess: [],
  usersFilterByRoleFail: ['error'],
});

const INITIAL_STATE = {
  data: [],
  status: '',
  isLoading: 0,
  pagination: [],
};

// LIST

const usersListRequest = (state) => ({ ...state, isLoading: 1, status: '' });
const usersListSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  data,
  pagination,
});
const usersListFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// CREATE

const usersCreateRequest = (state) => ({ ...state, isLoading: 1 });
const usersCreateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  data,
  status: 'create-success',
});

const usersCreateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error,
});

// UPDATE

const usersUpdateRequest = (state) => ({ ...state, isLoading: 1 });
const usersUpdateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  status: 'update-success',
  data,
});

const usersUpdateFail = (state, payload) => ({
  ...state,
  isLoading: 0,
  status: payload.message,
});

// DELETE

const usersDeleteRequest = (state) => ({ ...state, isLoading: 1 });
const usersDeleteSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  status: 'delete-success',
  data,
});

const usersDeleteFail = (state, payload) => ({
  ...state,
  isLoading: 0,
  status: payload.message,
});

// FILTER BY SEARCH NAME

const usersFilterBySearchRequest = (state) => ({ ...state, isLoading: 1 });
const usersFilterBySearchSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  data,
});
const usersFilterBySearchFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// FILTER BY ROLE

const usersFilterByRoleRequest = (state) => ({ ...state, isLoading: 1 });
const usersFilterByRoleSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  data,
});
const usersFilterByRoleFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.USERS_LIST_REQUEST]: usersListRequest,
  [Types.USERS_LIST_SUCCESS]: usersListSuccess,
  [Types.USERS_LIST_FAIL]: usersListFail,

  [Types.USERS_CREATE_REQUEST]: usersCreateRequest,
  [Types.USERS_CREATE_SUCCESS]: usersCreateSuccess,
  [Types.USERS_CREATE_FAIL]: usersCreateFail,

  [Types.USERS_UPDATE_REQUEST]: usersUpdateRequest,
  [Types.USERS_UPDATE_SUCCESS]: usersUpdateSuccess,
  [Types.USERS_UPDATE_FAIL]: usersUpdateFail,

  [Types.USERS_DELETE_REQUEST]: usersDeleteRequest,
  [Types.USERS_DELETE_SUCCESS]: usersDeleteSuccess,
  [Types.USERS_DELETE_FAIL]: usersDeleteFail,

  [Types.USERS_FILTER_BY_SEARCH_REQUEST]: usersFilterBySearchRequest,
  [Types.USERS_FILTER_BY_SEARCH_SUCCESS]: usersFilterBySearchSuccess,
  [Types.USERS_FILTER_BY_SEARCH_FAIL]: usersFilterBySearchFail,

  [Types.USERS_FILTER_BY_ROLE_REQUEST]: usersFilterByRoleRequest,
  [Types.USERS_FILTER_BY_ROLE_SUCCESS]: usersFilterByRoleSuccess,
  [Types.USERS_FILTER_BY_ROLE_FAIL]: usersFilterByRoleFail,
});
