import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormInput from 'components/core/FormInput';
import Button from 'components/core/Button';
import { Creators as ForgotPasswordActions } from 'store/ducks/forgotPasswordReducer';
import BackgroundHalfContainer from 'components/core/BackgroundHalfContainer';
import { Container, RowActions } from './styles';

const ForgotPasswordEmailForm = () => {
  const dispatch = useDispatch();
  const { isLoading, message } = useSelector(
    (state) => state.forgotPasswordReducer,
  );

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object().shape({
    email: yup.string().required(<FormattedMessage id="unfilledEmail" />),
  });

  return (
    <BackgroundHalfContainer
      loading={isLoading}
      title={<FormattedMessage id="resetPassword" />}
      text={<FormattedMessage id="resetPasswordDescription" />}
    >
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(ForgotPasswordActions.sendEmailRequest(values.email));
          }}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <Form>
              <Row>
                <Col md="12">
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    isError={(errors.email && touched.email) || message}
                    error={errors.email || message}
                  >
                    <FormattedMessage id="emailRequired" />
                  </FormInput>
                </Col>
              </Row>
              <RowActions>
                <Button
                  type="submit"
                  btnType="primary"
                  onClick={handleSubmit}
                  width="250px"
                  uppercase
                  fontWeight="600"
                >
                  <FormattedMessage id="continue" />
                </Button>
              </RowActions>
            </Form>
          )}
        </Formik>
      </Container>
    </BackgroundHalfContainer>
  );
};

export default ForgotPasswordEmailForm;
