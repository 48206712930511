import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > button {
    margin-top: 7px;
  }
`;

export const CustomImg = styled.img`
  width: 69px !important;
`;
