import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import theme from 'theme/';
import { Container, BlurBackground } from './styles';

const Loader = ({ loading, loadingText }) => (
  <Container loading={loading}>
    <BlurBackground />
    <ReactLoading
      type="spin"
      color={theme.colors.defaultColor}
      height={60}
      width={60}
      className="loader-spin"
    />
    <h3 className="loader-text">{loadingText}</h3>
  </Container>
);

export default Loader;

Loader.propTypes = {
  loading: PropTypes.number,
  loadingText: PropTypes.string,
};

Loader.defaultProps = {
  loading: 0,
  loadingText: '',
};
