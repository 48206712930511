import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  mediumRequest: ['page'],
  mediumSuccess: ['payload'],
  mediumFail: ['error'],

  mediumForSelectListRequest: [],
  mediumForSelectListSuccess: ['payload'],
  mediumForSelectListFail: ['error'],

  mediumCreateRequest: ['payload'],
  mediumCreateSuccess: [],
  mediumCreateFail: ['error'],

  mediumUpdateRequest: ['payload', 'id'],
  mediumUpdateSuccess: [],
  mediumUpdateFail: ['error'],

  mediumDeleteRequest: ['id'],
  mediumDeleteSuccess: [],
  mediumDeleteFail: ['error'],

  mediumFilterByDateRequest: ['startDate', 'endDate'],
  mediumFilterByDateSuccess: [],
  mediumFilterByDateFail: ['error'],

  mediumFilterByNameRequest: ['medium'],
  mediumFilterByNameSuccess: [],
  mediumFilterByNameFail: ['error'],
});

const INITIAL_STATE = {
  mediumData: [],
  mediumSelectData: [],
  isLoading: 0,
  pagination: [],
  status: '',
};

// LIST

const mediumRequest = () => ({ isLoading: 1, status: '' });

const mediumSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
  pagination,
});

const mediumFail = (state) => ({
  ...state,
  isLoading: 0,
});

// LIST REQUEST FOR SELECT

const mediumForSelectListRequest = () => ({ isLoading: 1 });

const mediumForSelectListSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  mediumSelectData: data,
});

const mediumForSelectListFail = (state) => ({
  ...state,
  isLoading: 0,
});

// CREATE

const mediumCreateRequest = () => ({ isLoading: 1 });

const mediumCreateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
  status: 'create-success',
});

const mediumCreateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// UPDATE

const mediumUpdateRequest = () => ({ isLoading: 1 });

const mediumUpdateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
  status: 'update-success',
});

const mediumUpdateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// DELETE

const mediumDeleteRequest = () => ({ isLoading: 1 });

const mediumDeleteSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
  status: 'delete-success',
});

const mediumDeleteFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// FILTER BY DATE

const mediumFilterByDateRequest = (state) => ({ ...state, isLoading: 1 });
const mediumFilterByDateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
});
const mediumFilterByDateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// FILTER BY NAME

const mediumFilterByNameRequest = (state) => ({ ...state, isLoading: 1 });
const mediumFilterByNameSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  mediumData: data,
  pagination,
});
const mediumFilterByNameFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.MEDIUM_REQUEST]: mediumRequest,
  [Types.MEDIUM_SUCCESS]: mediumSuccess,
  [Types.MEDIUM_FAIL]: mediumFail,

  [Types.MEDIUM_FOR_SELECT_LIST_REQUEST]: mediumForSelectListRequest,
  [Types.MEDIUM_FOR_SELECT_LIST_SUCCESS]: mediumForSelectListSuccess,
  [Types.MEDIUM_FOR_SELECT_LIST_FAIL]: mediumForSelectListFail,

  [Types.MEDIUM_CREATE_REQUEST]: mediumCreateRequest,
  [Types.MEDIUM_CREATE_SUCCESS]: mediumCreateSuccess,
  [Types.MEDIUM_CREATE_FAIL]: mediumCreateFail,

  [Types.MEDIUM_UPDATE_REQUEST]: mediumUpdateRequest,
  [Types.MEDIUM_UPDATE_SUCCESS]: mediumUpdateSuccess,
  [Types.MEDIUM_UPDATE_FAIL]: mediumUpdateFail,

  [Types.MEDIUM_DELETE_REQUEST]: mediumDeleteRequest,
  [Types.MEDIUM_DELETE_SUCCESS]: mediumDeleteSuccess,
  [Types.MEDIUM_DELETE_FAIL]: mediumDeleteFail,

  [Types.MEDIUM_FILTER_BY_DATE_REQUEST]: mediumFilterByDateRequest,
  [Types.MEDIUM_FILTER_BY_DATE_SUCCESS]: mediumFilterByDateSuccess,
  [Types.MEDIUM_FILTER_BY_DATE_FAIL]: mediumFilterByDateFail,

  [Types.MEDIUM_FILTER_BY_NAME_REQUEST]: mediumFilterByNameRequest,
  [Types.MEDIUM_FILTER_BY_NAME_SUCCESS]: mediumFilterByNameSuccess,
  [Types.MEDIUM_FILTER_BY_NAME_FAIL]: mediumFilterByNameFail,
});
