import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import SpanError from 'components/core/SpanError';
import Button from 'components/core/Button';
import eyeIcon from 'assets/icons/ic-no-eye.svg';
import errorIcon from 'assets/icons/ic-x-error.svg';
import { Container, Label, InputStyle } from './FormInputStyle';

const FormInput = ({
  type,
  id,
  name,
  value,
  onChange,
  onBlur,
  isError,
  error,
  children,
  isSearch,
  disabled,
  placeholder,
  onFocus,
  secondary,
  ...rest
}) => {
  const [passwordType, setPasswordType] = useState(type);

  const handleShowPassword = useCallback(() => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType(type);
    }
  }, [passwordType, type]);

  return (
    <Container>
      {!isSearch && (
        <Label htmlFor={name} isError={isError} secondary={secondary}>
          {children}
        </Label>
      )}
      <InputStyle
        type={type === 'password' ? passwordType : type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        isError={isError}
        isSearch={isSearch}
        placeholder={placeholder}
        disabled={disabled}
        onFocus={onFocus}
        secondary={secondary}
        {...rest}
      />
      {type === 'password' && (
        <Button
          type="button"
          className="show-password"
          width="30"
          style={{ right: isError ? '42px' : '14px' }}
          onClick={handleShowPassword}
        >
          <img src={eyeIcon} alt="" />
        </Button>
      )}
      {isError && <SpanError>{error}</SpanError>}
      {isError && <img src={errorIcon} alt="" className="error-icon" />}
    </Container>
  );
};

FormInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  error: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  isSearch: PropTypes.bool,
  placeholder: PropTypes.string,
  secondary: PropTypes.bool,
};

FormInput.defaultProps = {
  type: '',
  id: '',
  name: '',
  value: '',
  error: null,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  isError: false,
  disabled: false,
  isSearch: false,
  placeholder: '',
  secondary: false,
};

export default FormInput;
