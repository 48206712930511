import { takeLatest, put } from 'redux-saga/effects';
import { POST } from 'config/constants/verbs';
import { FORGOT_PASSWORD_USER_EMAIL } from 'config/constants/endPoints';
import urls from 'config/constants/urls';
import { history } from 'utils/routes';
import { Types } from '../ducks/forgotPasswordReducer';
import API from '../../utils/API';

const {
  FORGOT_PASSWORD_EMAIL_CONFIRMATION,
  FORGOT_PASSWORD_RESET_CONFIRMATION,
} = urls.ROUTES;

function* requestEmailSend({ payload }) {
  try {
    yield API.requestServer({
      verb: POST,
      endpoint: FORGOT_PASSWORD_USER_EMAIL,
      data: {
        email: payload,
      },
    });

    history.push(FORGOT_PASSWORD_EMAIL_CONFIRMATION.path);

    yield put({
      type: Types.SEND_EMAIL_SUCCESS,
      email: payload,
    });
  } catch (error) {
    yield put({
      type: Types.SEND_EMAIL_FAIL,
      message: error,
    });
  }
}

function* requesSendNewPassword({ password, token }) {
  try {
    yield API.requestServer({
      verb: POST,
      endpoint: `/auth/${token}/reset-password`,
      data: {
        newPassword: password,
      },
    });

    history.push(FORGOT_PASSWORD_RESET_CONFIRMATION.path);

    yield put({
      type: Types.SEND_NEW_PASSWORD_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.SEND_NEW_PASSWORD_FAIL,
      error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.SEND_EMAIL_REQUEST, requestEmailSend);
  yield takeLatest(Types.SEND_NEW_PASSWORD_REQUEST, requesSendNewPassword);
}
