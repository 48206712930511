import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  campaignRequest: ['page'],
  campaignSuccess: ['payload'],
  campaignFail: ['error'],

  campaignForSelectListRequest: [],
  campaignForSelectListSuccess: ['payload'],
  campaignForSelectListFail: ['error'],

  campaignCreateRequest: ['payload'],
  campaignCreateSuccess: [],
  campaignCreateFail: ['error'],

  campaignUpdateRequest: ['payload', 'id'],
  campaignUpdateSuccess: [],
  campaignUpdateFail: ['error'],

  campaignDeleteRequest: ['id'],
  campaignDeleteSuccess: [],
  campaignDeleteFail: ['error'],

  campaignFilterByDateRequest: ['startDate', 'endDate'],
  campaignFilterByDateSuccess: [],
  campaignFilterByDateFail: ['error'],

  campaignFilterByNameRequest: ['campaign'],
  campaignFilterByNameSuccess: [],
  campaignFilterByNameFail: ['error'],
});

const INITIAL_STATE = {
  campaignData: [],
  campaignSelectData: [],
  isLoading: 0,
  pagination: [],
  status: '',
};

// LIST

const campaignRequest = () => ({ isLoading: 1, status: '' });

const campaignSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
  pagination,
});

const campaignFail = (state) => ({
  ...state,
  isLoading: 0,
});

// LIST REQUEST FOR SELECT

const campaignForSelectListRequest = () => ({ isLoading: 1 });

const campaignForSelectListSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  campaignSelectData: data,
});

const campaignForSelectListFail = (state) => ({
  ...state,
  isLoading: 0,
});

// CREATE

const campaignCreateRequest = () => ({ isLoading: 1 });

const campaignCreateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
  status: 'create-success',
});

const campaignCreateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// UPDATE

const campaignUpdateRequest = () => ({ isLoading: 1 });

const campaignUpdateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
  status: 'update-success',
});

const campaignUpdateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// DELETE

const campaignDeleteRequest = () => ({ isLoading: 1 });

const campaignDeleteSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
  status: 'delete-success',
});

const campaignDeleteFail = (state, error) => ({
  ...state,
  isLoading: 0,
  status: error.error.data.message,
});

// FILTER BY DATE

const campaignFilterByDateRequest = (state) => ({ ...state, isLoading: 1 });
const campaignFilterByDateSuccess = (state, { data }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
});
const campaignFilterByDateFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

// FILTER BY NAME

const campaignFilterByNameRequest = (state) => ({ ...state, isLoading: 1 });
const campaignFilterByNameSuccess = (state, { data, pagination }) => ({
  ...state,
  isLoading: 0,
  campaignData: data,
  pagination,
});
const campaignFilterByNameFail = (state, error) => ({
  ...state,
  isLoading: 0,
  error,
});

export default createReducer(INITIAL_STATE, {
  [Types.CAMPAIGN_REQUEST]: campaignRequest,
  [Types.CAMPAIGN_SUCCESS]: campaignSuccess,
  [Types.CAMPAIGN_FAIL]: campaignFail,

  [Types.CAMPAIGN_FOR_SELECT_LIST_REQUEST]: campaignForSelectListRequest,
  [Types.CAMPAIGN_FOR_SELECT_LIST_SUCCESS]: campaignForSelectListSuccess,
  [Types.CAMPAIGN_FOR_SELECT_LIST_FAIL]: campaignForSelectListFail,

  [Types.CAMPAIGN_CREATE_REQUEST]: campaignCreateRequest,
  [Types.CAMPAIGN_CREATE_SUCCESS]: campaignCreateSuccess,
  [Types.CAMPAIGN_CREATE_FAIL]: campaignCreateFail,

  [Types.CAMPAIGN_UPDATE_REQUEST]: campaignUpdateRequest,
  [Types.CAMPAIGN_UPDATE_SUCCESS]: campaignUpdateSuccess,
  [Types.CAMPAIGN_UPDATE_FAIL]: campaignUpdateFail,

  [Types.CAMPAIGN_DELETE_REQUEST]: campaignDeleteRequest,
  [Types.CAMPAIGN_DELETE_SUCCESS]: campaignDeleteSuccess,
  [Types.CAMPAIGN_DELETE_FAIL]: campaignDeleteFail,

  [Types.CAMPAIGN_FILTER_BY_DATE_REQUEST]: campaignFilterByDateRequest,
  [Types.CAMPAIGN_FILTER_BY_DATE_SUCCESS]: campaignFilterByDateSuccess,
  [Types.CAMPAIGN_FILTER_BY_DATE_FAIL]: campaignFilterByDateFail,

  [Types.CAMPAIGN_FILTER_BY_NAME_REQUEST]: campaignFilterByNameRequest,
  [Types.CAMPAIGN_FILTER_BY_NAME_SUCCESS]: campaignFilterByNameSuccess,
  [Types.CAMPAIGN_FILTER_BY_NAME_FAIL]: campaignFilterByNameFail,
});
