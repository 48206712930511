import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BackgroundHalfContainer from 'components/core/BackgroundHalfContainer';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import FormInput from 'components/core/FormInput';
import Button from 'components/core/Button';
import { Creators as ForgotPasswordActions } from 'store/ducks/forgotPasswordReducer';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, RowActions, Body } from './styles';

const NewPasswordForm = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const intl = useIntl();

  const param = search.replace('?', '').replace('/reset-password', '');

  const { isLoading, error } = useSelector(
    (state) => state.forgotPasswordReducer,
  );

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(<FormattedMessage id="unfilledPassword" />)
      .matches(
        /^(?=.{6,16}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        <FormattedMessage id="passwordFormatAlert" />,
      ),
    confirmPassword: yup
      .string()
      .required(<FormattedMessage id="unfilledPassword" />)
      .oneOf(
        [yup.ref('password')],
        <FormattedMessage id="passwordMustToBeSame" />,
      ),
  });

  useEffect(() => {
    error.type &&
      toast.error(intl.formatMessage({ id: 'newPasswordCreationError' }));
  }, [error]);

  return (
    <BackgroundHalfContainer
      loading={isLoading}
      title={<FormattedMessage id="resetPassword" />}
      text={<FormattedMessage id="createNewPasswordDescription" />}
    >
      <Container>
        <Body>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(
                ForgotPasswordActions.sendNewPasswordRequest(
                  values.password,
                  param,
                ),
              );
            }}
          >
            {({ values, touched, errors, handleChange, handleSubmit }) => (
              <Form>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isError={errors.password && touched.password}
                      error={errors.password}
                    >
                      <FormattedMessage id="newPassword" />
                    </FormInput>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormInput
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      isError={
                        errors.confirmPassword && touched.confirmPassword
                      }
                      error={errors.confirmPassword}
                    >
                      <FormattedMessage id="confirmPassword" />
                    </FormInput>
                  </Col>
                </Row>
                <RowActions>
                  <Button
                    type="submit"
                    btnType="primary"
                    onClick={handleSubmit}
                    width="250px"
                    uppercase
                    fontWeight="600"
                  >
                    <FormattedMessage id="redefinePassword" />
                  </Button>
                </RowActions>
              </Form>
            )}
          </Formik>
        </Body>
      </Container>
    </BackgroundHalfContainer>
  );
};

export default NewPasswordForm;
