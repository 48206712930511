import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/core/Modal';
import Button from 'components/core/Button';
import successIcon from 'assets/icons/check-mark.svg';
import errorIcon from 'assets/icons/x-circle.svg';
import { Container } from './styles';

const ModalStatus = ({ status, children, text, isOpen, setIsOpen }) => {
  const confirmationContent = (
    <Container>
      {status === 'success' ? (
        <img src={successIcon} alt="" />
      ) : (
        <img src={errorIcon} alt="" />
      )}

      {text && <p>{text}</p>}

      {children}

      <Button
        btnType="primary"
        width="140px"
        borderRadius="4px"
        onClick={() => setIsOpen(false)}
      >
        OK
      </Button>
    </Container>
  );

  return (
    <Container>
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        content={confirmationContent}
      />
    </Container>
  );
};

ModalStatus.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
  setIsOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

ModalStatus.defaultProps = {
  text: '',
  setIsOpen: () => {},
  status: 'success',
  isOpen: false,
  children: null,
};

export default ModalStatus;
