import styled from 'styled-components';
import theme from 'theme/';
import Button from 'components/core/Button';

const { defaultColor } = theme.colors;

export const Container = styled.div`
  width: 579px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 20px;
  }
`;

export const QrcodeImage = styled.img`
  width: 250px;
  height: 250px;
  margin-bottom: 20px;
`;

export const DownloadButton = styled(Button)`
  font-family: 'Suvinil Sans';
  font-size: 14px;
  font-weight: 700;
  color: ${defaultColor};
  background: transparent;
  border: 0;
  padding: 0;

  > img {
    margin-left: 14px;
    margin-right: 0;
  }

  :hover {
    background: transparent;
  }
`;
