import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'components/core/Button';
import checkIcon from 'assets/icons/ic-green-check.svg';
import BackgroundHalfContainer from 'components/core/BackgroundHalfContainer';
import { Container, CustomImg } from './styles';

const PasswordResetConfirmation = () => (
  <BackgroundHalfContainer
    title={<FormattedMessage id="passwordCreated" />}
    customImg={<CustomImg src={checkIcon} />}
  >
    <Container>
      <Link to="/config/admin">
        <Button btnType="primary" width="250px" uppercase fontWeight="600">
          <FormattedMessage id="goToLogin" />
        </Button>
      </Link>
    </Container>
  </BackgroundHalfContainer>
);

export default PasswordResetConfirmation;
