import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 296px;

  > p {
    margin-bottom: 16px;
    font-family: 'Trebuchet MS';
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  button:first-child {
    margin-right: 12px;
  }
`;
