import React from 'react';
import Sidebar from 'components/core/Sidebar';
import { Switch, Route } from 'react-router';
import urls from 'config/constants/urls';
import MainContainer from 'components/core/MainContainer';
import QRCodeForm from 'components/presentation/QRCodeForm';
import QrCodesList from 'components/presentation/QrCodes/List';
import UsersList from 'components/presentation/UsersList';
import MediumList from 'components/presentation/MediumList';
import CampaignList from 'components/presentation/CampaignList';
import { CoreContainer } from './styles';

const Home = () => (
  <>
    <CoreContainer>
      <Sidebar />
      <MainContainer>
        <Switch>
          <Route
            exact
            path={urls.ROUTES.QR_CODES.path}
            component={QrCodesList}
          />
          <Route exact path={urls.LINKS.NEW_QR_CODES} component={QRCodeForm} />
          <Route exact path={urls.ROUTES.USERS.path} component={UsersList} />
          <Route exact path={urls.ROUTES.MEDIUM.path} component={MediumList} />
          <Route
            exact
            path={urls.ROUTES.CAMPAIGN.path}
            component={CampaignList}
          />
        </Switch>
      </MainContainer>
    </CoreContainer>
  </>
);
export default Home;
